export const orderInformation = (data) => {
  return [
    {
      name: "Order Number",
      content: data.orderNumber,
    },
    {
      name: "Customer",
      content: data.customerName + " (" + data.customerCode + ")",
    },
    {
      name: "Date",
      content: data.orderDate,
    },
    {
      name: "Shipping Address",
      content: data.shippingAddress,
    },
    {
      name: "Discount",
      content:
        (data.discountPercent * 1).toFixed(2) +
        "% ($" +
        (data.discountTotal * 1).toFixed(2) +
        ")",
    },
    {
      name: "Total",
      content: "$" + (data.orderTotal * 1).toFixed(2),
    },
    {
      name: "Status",
      content: data.orderStatus === "O" ? "Open" : "Closed",
    },
  ];
};

export const orderItemsColumn = [
  {
    id: "itemCode",
    label: "Item",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "quantity",
    label: "Quantity",
  },

  {
    id: "price",
    label: "Price",
  },
  {
    id: "itemTotal",
    label: "Total",
  },
];