import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";

function ProductDialog({ open, onModalClose, onChangeFilters }) {
  // Filter parameters
  const [product, setProduct] = useState("");
  const [name, setName] = useState("");

  // Changes the fetch URL based on entered filters and closes dialog
  const handleSubmit = (event) => {
    event.preventDefault();
    onChangeFilters("&product=" + product + "&name=" + name);
    onModalClose();
  };

  // Resets the fetch URL with no filters
  const handleClear = () => {
    setProduct("");
    setName("");
  };

  // Rendered content
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onModalClose}
        maxWidth="xs"
        fullWidth
        aria-labelledby="product-dialog"
      >
        <form
          autoComplete="off"
          noValidate
          onSubmit={(event) => handleSubmit(event)}
        >
          <DialogTitle id="product-dialog">Add Filters</DialogTitle>
          <DialogContent dividers style={{ overflow: "hidden" }}>
            <Grid
              container
              spacing={3}
              alignItems="flex-start"
              justifyContent="center"
              direction="column"
            >
              {/* Textfield Titles */}
              <Grid item container spacing={10}>
                {/* Customer Code Title */}
                <Grid item xs={6}>
                  <Typography component="h2" variant="h6">
                    Product Code
                  </Typography>
                </Grid>
                {/* Customer Name Title */}
                <Grid item xs={6}>
                  <Typography component="h3" variant="h6">
                    Product Name
                  </Typography>
                </Grid>
              </Grid>
              {/* Textfields */}
              <Grid item container spacing={10}>
                {/* Product Code Textfield */}
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Product Code"
                    value={product}
                    onChange={(event) => setProduct(event.target.value)}
                  />
                </Grid>
                {/* Product Code Textfield */}
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Product Name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          {/* Submit and Cancel Buttons */}
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={onModalClose}
            >
              Cancel
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClear}>
              Reset
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Apply
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

export default ProductDialog;
