import React from "react";
import { CircularProgress, Grid } from "@mui/material";

// Renders a progress bar when loading
function Loading() {
  return (
    <Grid container item xs={12} justifyContent="center" alignItems="center">
      <CircularProgress />
    </Grid>
  );
}

export default Loading;
