import React from "react";
import { Card, Grid, ListItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { blueGrey } from "@mui/material/colors";

// Renders the general card component for details pages
function DetailsCard({ children, title, data, color = blueGrey[600] }) {
  return (
    <Card variant="outlined">
      <List>
        <ListItem key={title}>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
        </ListItem>
        {data.map((row) => (
          <React.Fragment>
            <Divider component="li" key={row.name + "-divider"} />
            <ListItem key={row.name}>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Typography
                    sx={{ fontSize: 15, padding: 1.1 }}
                    variant="subtitle2"
                  >
                    {row.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: row.color ? row.color : blueGrey[600],
                      fontSize: 15,
                    }}
                    variant="body2"
                  >
                    {row.content}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </React.Fragment>
        ))}
        {children}
      </List>
    </Card>
  );
}

export default DetailsCard;
