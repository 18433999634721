import React from "react";
import PagedSortableTable from "../table/PagedSortableTable";
import { orderColumns } from "../../helpers/constants/constants";
import PageTitle from "../general/PageTitle";
import DashboardLink from "../general/links/DashboardLink";
import CurrentPage from "../general/links/CurrentPage";
import CreditMemoList from "./CreditMemoList";
import CreditMemoDialog from "./CreditMemoDialog";
import { TableCell } from "@mui/material";

// Primary credit memos component. Renders all credit memo content
function CreditMemos() {
  return (
    <React.Fragment>
      {/* Title */}
      <PageTitle title={"Your Credit Memos"} />
      {/* Link */}
      <div style={{ display: "flex" }}>
        <DashboardLink />
        <CurrentPage currentPage={"Credit Memos"} />
      </div>
      {/* Table */}
      <PagedSortableTable
        baseUrl={"/creditmemos"}
        columns={orderColumns}
        title="Credit Memos"
        BodyComponent={CreditMemoList}
        DialogComponent={CreditMemoDialog}
        extra={<TableCell>Details</TableCell>}
      />
    </React.Fragment>
  );
}

export default CreditMemos;
