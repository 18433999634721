import React from "react";
import Loading from "../general/Loading";
import SimpleTable from "../table/SimpleTable";
import { Paper } from "@mui/material";
import { invoiceColumns } from "../../helpers/constants/constants";
import { useNavigate } from "react-router-dom";
import SeeAllLink from "./SeeAllLink";
import InvoiceList from "../invoices/InvoiceList";
import useQueryPaged from "../../helpers/hooks/useQueryPaged";

// Renders recent invoices paper
function RecentInvoices() {
  // Data fetching
  const { data, isLoading } = useQueryPaged(
    "/invoices",
    0,
    10,
    "",
    "asc",
    "",
    ""
  );
  const navigate = useNavigate();

  // See all invoices link; redirects to invoices page
  const handleClick = () => {
    navigate("/invoices");
  };

  // Render loading if data not done fetching
  if (isLoading) return <Loading />;

  return (
    <Paper variant="outlined">
      {/* Table */}
      <SimpleTable
        title="Your Recent Invoices"
        columns={invoiceColumns}
        details={true}
      >
        <InvoiceList data={data.results} />
      </SimpleTable>
      {/* Link */}
      <SeeAllLink text="See all invoices" onClick={handleClick} />
    </Paper>
  );
}

export default RecentInvoices;
