import React from "react";
import { Grid, Paper } from "@mui/material";
import CustomRadialBar from "./CustomRadialBar";
import Typography from "@mui/material/Typography";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@mui/styles";
import { green, red } from "@mui/material/colors";

// CSS styling
const useStyles = makeStyles((theme) => ({
  up: {
    color: green[700],
    backgroundColor: green[100],
  },
  down: {
    color: red[700],
    backgroundColor: red[100],
  },
  margin: {
    marginLeft: theme.spacing(5),
    paddingRight: theme.spacing(2),
  },
  paper: {
    alignItems: "center",
    display: "flex",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    margin: "auto",
    height: 200,
  },
}));

// Renders radial bar graph paper
function RadialBarChartPaper({ data, caption, endDomain, subtitle }) {
  const classes = useStyles();

  return (
    <Paper variant="outlined">
      <Grid container spacing={3} alignItems="center" sx={{ padding: 2 }}>
        <Grid item xs={4}>
          <CustomRadialBar
            data={[
              {
                name: "monthlyTotal",
                value: data,
                fill: "#8dd1e1",
              },
            ]}
            domainEnd={endDomain}
          />
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h4" color="primary">
            {caption}
          </Typography>
          <Typography variant="subtitle2">{subtitle}</Typography>
        </Grid>
        <Grid item xs={1}>
          {data >= endDomain ? (
            <KeyboardArrowUpIcon className={classes.up} />
          ) : (
            <KeyboardArrowDownIcon className={classes.down} />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default RadialBarChartPaper;
