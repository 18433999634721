import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import axiosInstance from "../../helpers/axios";
import { useNavigate, useParams } from "react-router-dom";

// CSS styling
const useStyles = makeStyles({
  long: {
    width: 400,
  },
  selector: {
    width: 150,
  },
});

// Renders general user form component for adding and editing new users
function UserForm({
  username,
  setUsername,
  password,
  setPassword,
  email,
  setEmail,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  userType,
  setUserType,
  salesRepCode,
  setSalesRepCode,
  error,
  errorMessage,
  active,
  setActive,
  warehouse,
  setWarehouse,
  method,
}) {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      {/* Username title */}
      <Grid item xs={6}>
        <Typography variant="h6">Username</Typography>
      </Grid>
      {/* Password title */}
      <Grid item xs={6}>
        <Typography variant="h6">Password</Typography>
      </Grid>
      {/* Username textfield */}
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          label="Username"
          value={username}
          required
          onChange={(event) => setUsername(event.target.value)}
          error={error && username === ""}
        />
      </Grid>
      {/* Password textfield */}
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          label="Password"
          value={password}
          required={method === "create" ? true : false}
          type="password"
          onChange={(event) => setPassword(event.target.value)}
          error={method === "create" && error && password === ""}
        />
      </Grid>
      {/* Email title */}
      <Grid item xs={12}>
        <Typography variant="h6">Email</Typography>
      </Grid>
      {/* Email textfield */}
      <Grid item xs={12}>
        <TextField
          className={classes.long}
          variant="outlined"
          label="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </Grid>
      {/* First name title */}
      <Grid item xs={6}>
        <Typography variant="h6">First Name</Typography>
      </Grid>
      {/* Last name title */}
      <Grid item xs={6}>
        <Typography variant="h6">Last Name</Typography>
      </Grid>
      {/* First name textfield */}
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          label="First Name"
          value={firstName}
          required
          onChange={(event) => setFirstName(event.target.value)}
          error={error && firstName === ""}
        />
      </Grid>
      {/* Last name textfield */}
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          label="Last Name"
          value={lastName}
          required
          onChange={(event) => setLastName(event.target.value)}
          error={error && lastName === ""}
        />
      </Grid>
      {/* User type title */}
      <Grid item xs={6}>
        <Typography variant="h6">User Type</Typography>
      </Grid>
      {/* Sales rep code title */}
      <Grid item xs={6}>
        <Typography variant="h6">Sales Rep Code</Typography>
      </Grid>
      {/* User type selector */}
      <Grid item xs={6}>
        <TextField
          className={classes.selector}
          label="User Type"
          onChange={(event) => setUserType(event.target.value)}
          value={userType}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          select
          required
          error={error && userType === ""}
        >
          <MenuItem value="1">Admin</MenuItem>
          <MenuItem value="2">Sales Rep</MenuItem>
          <MenuItem value="3">Warehouse</MenuItem>
        </TextField>
      </Grid>
      {/* Sales rep code textfield */}
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          label="Sales Rep Code"
          value={salesRepCode}
          onChange={(event) => setSalesRepCode(event.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">Warehouse</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">Active</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          label="Warehouse"
          value={warehouse}
          onChange={(event) => setWarehouse(event.target.value)}
        />
      </Grid>
      {/* Is active checkbox */}
      <Grid item xs={6}>
        <TextField
          label="Active"
          onChange={(event) => setActive(event.target.value)}
          value={active}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          select
          required
          error={error && userType === ""}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </TextField>
      </Grid>
      {/* Red textfields for empty required textfields */}
      {error && (
        <Grid item container xs={12} justifyContent="center">
          <Typography color="error">
            There was a problem with your submission
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default UserForm;
