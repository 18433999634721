// import axiosInstance from "../../helpers/axios";
// import { useNavigate } from "react-router-dom";

import { Divider, Grid, IconButton, ListItem } from "@mui/material";
import { useState } from "react";
import { monthlyBreakdownData } from "../../helpers/constants/dashboardConstants";
import DetailsCard from "../general/DetailsCard";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// Renders top 5 customers paper
function MonthlyBreakdown({ data }) {
  // let fake = {};
  // for (let i = 0; i < 8; i++) {
  //   fake[`month${i}`] = {};
  //   fake[`month${i}`]["name"] = i;
  //   fake[`month${i}`]["difference"] = 100;
  //   fake[`month${i}`]["percentDifference"] = 50;
  // }
  // fake["year"] = {};
  // fake["year"]["numCurrentMonth"] = 8;
  // console.log(fake);

  const [isPrevPage, setIsPrevPage] = useState(false);
  const cardData = monthlyBreakdownData(data, isPrevPage);

  const handlePageChange = (direction) => {
    if (data.year.numCurrentMonth > 6) {
      if (direction === "back" && !isPrevPage) {
        setIsPrevPage(true);
      }
      if (direction === "forward" && isPrevPage) {
        setIsPrevPage(false);
      }
    }
  };

  return (
    <DetailsCard title="Monthly Breakdown" data={cardData}>
      <Divider component="li" />
      <ListItem sx={{ padding: 0 }}>
        <Grid
          container
          justifyContent="space-between"
          sx={{ justifyItems: "center" }}
        >
          <Grid item xs={6} sx={{ textAlign: "left" }}>
            <IconButton
              sx={{ padding: 0, marginLeft: 3 }}
              onClick={() => handlePageChange("back")}
            >
              <ChevronLeftIcon sx={{ fontSize: 40 }} />
            </IconButton>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <IconButton
              sx={{ padding: 0, marginRight: 3 }}
              onClick={() => handlePageChange("forward")}
            >
              <ChevronRightIcon sx={{ fontSize: 40 }} />
            </IconButton>
          </Grid>
        </Grid>
      </ListItem>

      {/* <Divider component="li" />
      <ListItem>
        <form
          autoComplete="off"
          noValidate
          onSubmit={(event) => handleSetGoal(goal)}
        >
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Goal"
                variant="outlined"
                defaultValue={data.percentGoal}
                onChange={(event) => {
                  setGoal(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Button variant="text" type="submit">
                Set Goal
              </Button>
            </Grid>
          </Grid>
        </form>
      </ListItem> */}
    </DetailsCard>
  );
}

export default MonthlyBreakdown;
