import React, { useContext } from "react";
import PagedSortableTable from "../table/PagedSortableTable";
import InventoryList from "./InventoryList";
import { inventoryColumns } from "../../helpers/constants/constants";
import InventoryDialog from "./InventoryDialog";
import PageTitle from "../general/PageTitle";
import DashboardLink from "../general/links/DashboardLink";
import CurrentPage from "../general/links/CurrentPage";
import { UserContext } from "../../helpers/UserContext";

// Primary inventory component. Renders all inventory content
function Inventory() {
  const { user } = useContext(UserContext);

  return (
    <React.Fragment>
      {/* Title */}
      <PageTitle title={"Inventory"} />
      {/* Link */}
      <div style={{ display: "flex" }}>
        <DashboardLink />
        <CurrentPage currentPage={"Inventory"} />
      </div>
      {/* Table */}
      <PagedSortableTable
        baseUrl={"/inventory"}
        columns={inventoryColumns}
        title="Inventory"
        BodyComponent={InventoryList}
        DialogComponent={InventoryDialog}
        defaultFilter={`warehouse=${user.warehouse}`}
      />
    </React.Fragment>
  );
}

export default Inventory;
