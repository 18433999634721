import React, { useState } from "react";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from "recharts";
import { grey, indigo, red } from "@mui/material/colors";
import { pastSixMonthsData } from "../../helpers/constants/dashboardConstants";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

//! Legacy styling (MUI v.4)
const useStyles = makeStyles({
  table: {
    marginLeft: 20,
    marginTop: 15,
  },
  tableSub: {
    color: grey[600],
    marginLeft: 20,
    marginTop: -12,
  },
  graphType: {
    marginRight: 30,
    marginTop: 20,
  },
  font: {
    fontFamily: "Roboto",
    fontSize: 15,
  },
});

// Renders bar graph paper with past 6 months data
function SixMonthBar({ data }) {
  // let fake = {};
  // for (let i = 0; i < 1; i++) {
  //   fake[`month${i}`] = {};
  //   fake[`month${i}`]["name"] = i;
  //   fake[`month${i}`]["prevYear"] = 100;
  //   fake[`month${i}`]["currentYear"] = 50;
  // }
  // fake["year"] = {};
  // fake["year"]["numCurrentMonth"] = 1;
  // console.log(fake);

  const classes = useStyles();
  const [graphType, setGraphType] = useState("bar");
  const [isPrevPage, setIsPrevPage] = useState(false);
  const chartData = pastSixMonthsData(data, isPrevPage);

  const handleGraphChange = (graphType) => {
    setGraphType(graphType);
  };

  const handlePageChange = (direction) => {
    if (data.year.numCurrentMonth > 6) {
      if (direction === "back" && !isPrevPage) {
        setIsPrevPage(true);
      }
      if (direction === "forward" && isPrevPage) {
        setIsPrevPage(false);
      }
    }
  };

  return (
    <Paper variant="outlined">
      <Box sx={{ position: "relative" }}>
        {/* Title */}
        <Grid container space={2} direction="column">
          <Grid container item space={2} justifyContent="space-between">
            <Grid item>
              <Typography className={classes.table} variant="h6">
                Sales History
              </Typography>
            </Grid>
            <Grid item>
              <ButtonGroup
                variant="contained"
                color="primary"
                aria-label="contained primary button group"
                className={classes.graphType}
              >
                <Button
                  value="bar"
                  onClick={(event) =>
                    handleGraphChange(event.currentTarget.value)
                  }
                >
                  Bar
                </Button>
                <Button
                  value="line"
                  onClick={(event) =>
                    handleGraphChange(event.currentTarget.value)
                  }
                >
                  Line
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid item>
            {/* Subtitle */}
            <Typography className={classes.tableSub} variant="subtitle2">
              Previous Year vs. Current Year
            </Typography>
          </Grid>
        </Grid>
        {/* Bar graph */}
        <ResponsiveContainer height={400}>
          <ComposedChart
            className={classes.font}
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" orientation="left" />
            {/*<YAxis yAxisId="right" orientation="right" stroke={indigo[300]} />*/}
            <Tooltip
              formatter={(value) =>
                value.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }
            />
            <Legend />
            {graphType === "bar" ? (
              <Bar
                yAxisId="left"
                dataKey="prevSales"
                fill={red[300]}
                animationDuration={1000}
                name="Last Year's Sales"
              />
            ) : (
              <Line
                type="monotone"
                dataKey="prevSales"
                stroke={red[300]}
                yAxisId="left"
                animationDuration={1000}
                name="Last Year's Sales"
              />
            )}
            {graphType === "bar" ? (
              <Bar
                yAxisId="left"
                dataKey="sales"
                fill={indigo[300]}
                animationDuration={1000}
                animationBegin={500}
                name="This Year's Sales"
              />
            ) : (
              <Line
                type="monotone"
                dataKey="sales"
                stroke={indigo[300]}
                yAxisId="left"
                animationDuration={1000}
                animationBegin={500}
                name="This Year's Sales"
              />
            )}
            {/* <Line
              type="monotone"
              dataKey="goal"
              stroke={teal[500]}
              yAxisId="left"
              animationDuration={1000}
              animationBegin={500}
              name="Goal"
            /> */}
          </ComposedChart>
        </ResponsiveContainer>
        {data.year.numCurrentMonth > 6 && (
          <React.Fragment>
            <IconButton
              sx={{
                position: "absolute",
                bottom: -10,
                left: { xs: 10, sm: 35 },
              }}
              onClick={() => handlePageChange("back")}
            >
              <ChevronLeftIcon sx={{ fontSize: 50 }} />
            </IconButton>
            <IconButton
              sx={{
                position: "absolute",
                bottom: -10,
                right: { xs: 10, sm: 35 },
              }}
              onClick={() => handlePageChange("forward")}
            >
              <ChevronRightIcon sx={{ fontSize: 50 }} />
            </IconButton>
          </React.Fragment>
        )}
      </Box>
    </Paper>
  );
}

export default SixMonthBar;
