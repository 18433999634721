import React from "react";
import { TableCell, TableRow } from "@mui/material";

// Renders product data into table rows
function ProductList({ data }) {
  return (
    <React.Fragment>
      {data.map((product) => (
        <TableRow key={product.productCode} hover>
          <TableCell>{product.productCode}</TableCell>
          <TableCell>{product.productName}</TableCell>
          <TableCell>{product.barcode}</TableCell>
          <TableCell>{product.unitsPerInner}</TableCell>
          <TableCell>{product.unitsPerCarton}</TableCell>
          <TableCell>{"$" + (product.retailPrice * 1).toFixed(2)}</TableCell>
          <TableCell>{"$" + (product.quantityPrice * 1).toFixed(2)}</TableCell>
          <TableCell>{"$" + (product.wholesalePrice * 1).toFixed(2)}</TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

export default ProductList;
