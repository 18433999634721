import React from "react";
import { Chip, Link, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import DownloadButton from "../general/DownloadButton";

// CSS styles
const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.light,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
  canceled: {
    color: red[600],
  },
}));

// Renders invoice data into table rows
function InvoiceList({ data, isCreditMemo = false }) {
  const classes = useStyles();

  const handleTracking = (trackingNumber) => {
    if (trackingNumber.includes("ZE")) {
      return (
        <Link
          href={`https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNumber}`}
          target="_blank"
        >
          {trackingNumber}
        </Link>
      );
    } else if (trackingNumber[0] === "5" && trackingNumber[1] === "8") {
      return (
        <Link
          href={`https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`}
          target="_blank"
        >
          {trackingNumber}
        </Link>
      );
    } else {
      return (
        <Link
          href={`https://parcelsapp.com/en/tracking/${trackingNumber}`}
          target="_blank"
        >
          {trackingNumber}
        </Link>
      );
    }
  };
  // Renders chip for open and close statuses
  const handleStatus = (status) => {
    if (status === "O") {
      return <Chip label="Open" className={classes.warning} />;
    } else if (status === "C") {
      return <Chip label="Closed" className={classes.success} />;
    }
  };

  // Correctly calculates total for canceled orders and credit memos
  const handleCanceled = (invoice) => {
    if (invoice.canceled === "C") {
      return (
        <Typography variant="body2" className={classes.canceled}>
          {"-$" + (invoice.total * -1).toFixed(2)}
        </Typography>
      );
    }
    return "$" + (invoice.total * 1).toFixed(2);
  };

  return (
    <React.Fragment>
      {data.map((invoice) => (
        <TableRow key={invoice.orderId} hover>
          <TableCell>{invoice.orderNumber}</TableCell>
          <TableCell>{invoice.orderDate}</TableCell>
          <TableCell>{invoice.customerCode}</TableCell>
          <TableCell>{invoice.shippingAddressName}</TableCell>
          <TableCell>
            {invoice.trackingNumber
              ? handleTracking(invoice.trackingNumber)
              : ""}
          </TableCell>
          <TableCell>{handleCanceled(invoice)}</TableCell>
          <TableCell>
            <DownloadButton
              fileInfo={{
                orderId: invoice.orderId,
                orderNumber: invoice.orderNumber,
                customerCode: invoice.customerCode,
              }}
              fileType={"invoice"}
            />
          </TableCell>
          <TableCell>{invoice.daysOpen}</TableCell>
          <TableCell>{handleStatus(invoice.orderStatus)}</TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

export default InvoiceList;
