import { Chip, TableCell, TableRow, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";
import DownloadButton from "../general/DownloadButton";
import { makeStyles } from "@mui/styles";

// CSS styles
const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.light,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
  canceled: {
    color: red[600],
  },
}));

// Renders credit memo data into table rows. Simply renders InvoiceList component as they are identical
function CreditMemoList({ data }) {
  const classes = useStyles();

  // Renders chip for open and close statuses
  const handleStatus = (status) => {
    if (status === "O") {
      return <Chip label="Open" className={classes.warning} />;
    } else if (status === "C") {
      return <Chip label="Closed" className={classes.success} />;
    }
  };

  // Correctly calculates total for canceled orders and credit memos
  const handleCanceled = (creditMemo) => {
    if (creditMemo.canceled === "C") {
      return (
        <Typography variant="body2" className={classes.canceled}>
          {"$" + (creditMemo.total * 1).toFixed(2)}
        </Typography>
      );
    } else {
      return "-$" + (creditMemo.total * -1).toFixed(2);
    }
  };

  return (
    <React.Fragment>
      {data.map((creditMemo) => (
        <TableRow key={creditMemo.orderId} hover>
          <TableCell>{creditMemo.orderNumber}</TableCell>
          <TableCell>{creditMemo.orderDate}</TableCell>
          <TableCell>{creditMemo.customerCode}</TableCell>
          <TableCell>{creditMemo.shippingAddressName}</TableCell>
          <TableCell>{handleStatus(creditMemo.orderStatus)}</TableCell>
          <TableCell>{handleCanceled(creditMemo)}</TableCell>
          <TableCell>
            <DownloadButton
              fileInfo={{
                orderNumber: creditMemo.orderNumber,
                customerCode: creditMemo.customerCode,
              }}
              fileType={"creditmemo"}
            />
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

export default CreditMemoList;
