import React from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import DetailsCard from "../general/DetailsCard";
import PageTitle from "../general/PageTitle";
import PagedTable from "../table/PagedTable";
import OrderDetailsList from "./OrderDetailsList";
import Loading from "../general/Loading";
import usePagination from "../../helpers/hooks/usePagination";
import NavigationLink from "../general/links/NavigationLink";
import DashboardLink from "../general/links/DashboardLink";
import CurrentPage from "../general/links/CurrentPage";
import {
  orderInformation,
  orderItemsColumn,
} from "../../helpers/constants/orderDetailsConstants";
import useQueryUrl from "../../helpers/hooks/useQueryUrl";

// Renders detail page for individual orders
function OrderDetails() {
  const { id } = useParams();
  const { currentPage, pageSize, handlePageChange, handleRowsPerPageChange } =
    usePagination();
  const { data, isLoading } = useQueryUrl("/orders/" + id);

  if (isLoading) return <Loading />;

  return (
    <React.Fragment>
      {/* Title */}
      <PageTitle title={`Order Details`} />
      {/* Link */}
      <div style={{ display: "flex" }}>
        <DashboardLink />
        <NavigationLink name="Orders" url="/orders" />
        <CurrentPage currentPage="Order Details" />
      </div>
      {/* Card layout */}
      <Grid container spacing={3}>
        {/* General order info */}
        <Grid item xs={12} md={4}>
          <DetailsCard
            title="Order Information"
            data={orderInformation(data)}
          />
        </Grid>
        {/* Order items */}
        <Grid item xs={12} md={8}>
          <PagedTable
            title="Order Items"
            columns={orderItemsColumn}
            length={data.orderItems.length}
            currentPage={currentPage}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          >
            <OrderDetailsList
              items={data.orderItems}
              currentPage={currentPage}
              pageSize={pageSize}
            />
          </PagedTable>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default OrderDetails;
