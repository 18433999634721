import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    color: blueGrey[600],
  },
});

function BackOrderDetailsList({ items, currentPage, pageSize }) {
  const classes = useStyles();
  const endIndex =
    pageSize === -1 ? items.length : currentPage * pageSize + pageSize;
  return (
    <React.Fragment>
      {items.slice(currentPage * pageSize, endIndex).map((item) => (
        <TableRow key={item.itemNumber}>
          <TableCell>
            <Typography className={classes.root} variant="body2">
              {item.itemCode}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.root} variant="body2">
              {item.warehouse}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.root} variant="body2">
              {"$" + (item.price * 1).toFixed(2)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.root} variant="body2">
              {(item.ordered * 1).toFixed(0)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.root} variant="body2">
              {"$" + (item.orderedTotal * 1).toFixed(2)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.root} variant="body2">
              {(item.backOrdered * 1).toFixed(0)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.root} variant="body2">
              {"$" + (item.backOrderedTotal * 1).toFixed(2)}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

export default BackOrderDetailsList;
