import React, { useState } from "react";

// Handles table pagination logic
const UsePagination = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (size) => {
    setPageSize(size);
    setCurrentPage(0);
  };

  return { currentPage, pageSize, handlePageChange, handleRowsPerPageChange };
};

export default UsePagination;