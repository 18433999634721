import {
  List,
  ListItem,
  ListItemIcon,
  Toolbar,
  Typography,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  menuItems,
  userDictionary,
} from "../../../helpers/constants/constants";
import { UserContext } from "../../../helpers/UserContext";
import DrawerHeader from "./DrawerHeader";

function DrawerContent({ mobileOpen, setMobileOpen }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);

  // Toggles the drawer open or close
  const handleSelectItem = (path) => {
    setMobileOpen(!mobileOpen);
    navigate(path);
  };

  return (
    <React.Fragment>
      <Toolbar />
      <DrawerHeader />
      <List>
        {menuItems.map(
          (item) =>
            (item.permissions.includes(
              userDictionary[user.user_type]["type"]
            ) ||
              userDictionary[user.user_type]["type"] === "admin") && (
              <ListItem
                button
                key={item.text}
                onClick={() => handleSelectItem(item.path)}
                sx={{ height: { xs: 45, lg: 40 } }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === item.path
                        ? "primary.main"
                        : blueGrey[600],
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <Typography
                  sx={{
                    color:
                      location.pathname === item.path
                        ? "primary.main"
                        : blueGrey[600],
                    fontWeight: "medium",
                    fontSize: { xs: 17, lg: 16 },
                  }}
                >
                  {item.text}
                </Typography>
              </ListItem>
            )
        )}
      </List>
    </React.Fragment>
  );
}

export default DrawerContent;
