import Typography from "@mui/material/Typography";
import React from "react";

// Renders the page title
function PageTitle({ title }) {
  return (
    <Typography component="h1" variant="h4" gutterBottom>
      {title}
    </Typography>
  );
}

export default PageTitle;
