import PagedSortableTable from "../table/PagedSortableTable";
import React from "react";
import ProductList from "./ProductList";
import { productColumns } from "../../helpers/constants/constants";
import ProductDialog from "./ProductDialog";
import PageTitle from "../general/PageTitle";
import DashboardLink from "../general/links/DashboardLink";
import CurrentPage from "../general/links/CurrentPage";

// Primary product component. Renders all product content
function Products() {
  return (
    <React.Fragment>
      {/* Title */}
      <PageTitle title={"Products"} />
      {/* Link */}
      <div style={{ display: "flex" }}>
        <DashboardLink />
        <CurrentPage currentPage={"Products"} />
      </div>
      {/* Table */}
      <PagedSortableTable
        baseUrl={"/products"}
        columns={productColumns}
        title="Products"
        BodyComponent={ProductList}
        DialogComponent={ProductDialog}
      />
    </React.Fragment>
  );
}

export default Products;
