import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

// CSS styling
const useStyles = makeStyles({
  customer: {
    width: 300,
  },
  status: {
    width: 150,
  },
  date: {
    width: 150,
  },
});

// Renders the orders filter dialog box
function OrderDialog({ open, onModalClose, onChangeFilters }) {
  const classes = useStyles();

  // Filter parameters
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customer, setCustomer] = useState("");
  const [status, setStatus] = useState("");

  // Changes the fetch URL based on entered filters and closes the dialog
  const handleSubmit = (event) => {
    event.preventDefault();
    onChangeFilters(
      "&dateStart=" +
        startDate +
        "&dateEnd=" +
        endDate +
        "&customer=" +
        customer +
        "&orderStatus=" +
        status
    );
    onModalClose();
  };

  // Resets the fetch URL with no filters
  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setCustomer("");
    setStatus("");
  };

  // Rendered content
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onModalClose}
        maxWidth="xs"
        fullWidth
        aria-labelledby="order-dialog"
      >
        <form
          autoComplete="off"
          noValidate
          onSubmit={(event) => handleSubmit(event)}
        >
          <DialogTitle id="order-dialog">Add Filters</DialogTitle>
          <DialogContent dividers style={{ overflow: "hidden" }}>
            <Grid
              container
              spacing={3}
              alignItems="flex-start"
              justifyContent="center"
              direction="column"
            >
              {/* Date Selector Title Row */}
              <Grid container item spacing={3}>
                <Grid item xs={6}>
                  <Typography component="h2" variant="h6">
                    Start Date
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography component="h3" variant="h6">
                    End Date
                  </Typography>
                </Grid>
              </Grid>
              {/* Date Textfield Row */}
              <Grid item container spacing={3}>
                <Grid item xs={6}>
                  {/* Start Date Textfield */}
                  <TextField
                    variant="outlined"
                    label="From"
                    type="date"
                    value={startDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => setStartDate(event.target.value)}
                    fullWidth
                  />
                </Grid>
                {/* End Date Textfield */}
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="To"
                    type="date"
                    value={endDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => setEndDate(event.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
              {/* Customer Code Title */}
              <Grid item xs={12}>
                <Typography component="h2" variant="h6">
                  Customer Code
                </Typography>
              </Grid>
              {/* Customer Textfield */}
              <Grid item xs={12}>
                <TextField
                  className={classes.customer}
                  variant="outlined"
                  label="Customer"
                  value={customer}
                  onChange={(event) => setCustomer(event.target.value)}
                />
              </Grid>
              {/* Status Title */}
              <Grid item xs={12}>
                <Typography component="h2" variant="h6">
                  Status
                </Typography>
              </Grid>
              {/* Status Selector */}
              <Grid item xs={12}>
                <TextField
                  className={classes.status}
                  label="Status"
                  onChange={(event) => setStatus(event.target.value)}
                  value={status}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  select
                >
                  <MenuItem value="" label="All">
                    All
                  </MenuItem>
                  <MenuItem value="C">Closed</MenuItem>
                  <MenuItem value="O">Open</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          {/* Submit and Cancel Buttons */}
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={onModalClose}
            >
              Cancel
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClear}>
              Reset
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Apply
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

export default OrderDialog;
