import { Grid, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import React, { Fragment } from "react";
import { formatMoney, formatPercent } from "../../helpers/functions";
import InfoCard from "./InfoCard";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

function InfoCardsPair({ data, difference, percentDifference, subtitle }) {
  return (
    <Fragment>
      <Grid item xs={6}>
        <InfoCard
          data={formatMoney(data)}
          subtitle={subtitle}
          background={difference > 0 ? green["A100"] : red[100]}
          size={10}
        >
          {difference > 0 ? (
            <TrendingUpIcon sx={{ color: green[400], fontSize: 40 }} />
          ) : (
            <TrendingDownIcon sx={{ color: red[400], fontSize: 40 }} />
          )}
        </InfoCard>
      </Grid>
      <Grid item xs={6}>
        <InfoCard
          data={formatMoney(difference)}
          subtitle={"Difference"}
          background={difference > 0 ? green["A100"] : red[100]}
          size={9}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontSize: 12 }}
          >{`(${formatPercent(percentDifference)})`}</Typography>
        </InfoCard>
      </Grid>
    </Fragment>
  );
}

export default InfoCardsPair;
