import React, { useEffect, useState } from "react";
import Loading from "../general/Loading";
import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  Toolbar,
} from "@mui/material";
import TableFilters from "./TableFilters";
import HeadRows from "./HeadRows";
import Typography from "@mui/material/Typography";
import useQueryPaged from "../../helpers/hooks/useQueryPaged";

// Renders a table that is paginated and sortable.
function PagedSortableTable({
  baseUrl,
  columns,
  title,
  BodyComponent,
  DialogComponent,
  defaultFilter = "",
  extra = null,
}) {
  // Data fetching
  // const [url, setUrl] = useState(
  //   baseUrl + "?limit=10&offset=0&ordering=&search=" + defaultFilter
  // );

  // Table filtering parameters
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState(defaultFilter);

  // Data fetching
  const { data, isLoading } = useQueryPaged(
    baseUrl,
    currentPage,
    pageSize,
    orderBy,
    order,
    search,
    filters
  );

  // Change the URL and refetch everytime parameters change
  // useEffect(() => {
  //   setUrl(
  //     getUrl(baseUrl, currentPage, pageSize, orderBy, order, search, filters)
  //   );
  // }, [baseUrl, currentPage, pageSize, orderBy, order, search, filters]);

  // Go to the first page every time parameters change
  useEffect(() => {
    if (currentPage) {
      setCurrentPage(0);
    }
  }, [search, filters]);

  // Handles changing page
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Handles sorting table
  const handleTableSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Handles changing page size
  const handleRowsPerPageChange = (size) => {
    setPageSize(size);
    setCurrentPage(0);
  };

  // Handles extra filters
  const handleChangeFilters = (filter) => {
    setFilters(filter);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Grid container spacing={1.5}>
      <Grid
        // sx={{ border: 1 }}
        item
        container
        xs={12}
        alignItems="flex-end"
        justifyContent="space-between"
        spacing={1.5}
      >
        {/* Table filters component */}
        <TableFilters
          title={title}
          setSearch={setSearch}
          onChangeFilters={handleChangeFilters}
          FilterDialog={DialogComponent}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper variant="outlined" elevation={3}>
          {/* Table title */}
          <Toolbar>
            <Typography variant="h6">{title}</Typography>
          </Toolbar>
          <Divider />
          {/* Table */}
          <TableContainer>
            <Table>
              {/* Table Head */}
              <TableHead>
                <HeadRows
                  columns={columns}
                  orderBy={orderBy}
                  order={order}
                  onTableSort={handleTableSort}
                  // extra={extra}
                />
              </TableHead>
              {/* Table body */}
              <TableBody>
                <BodyComponent data={data.results} />
              </TableBody>
            </Table>
          </TableContainer>
          {/* Table Pagination */}
          <TablePagination
            count={data.count}
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={(event, page) => handlePageChange(page)}
            onRowsPerPageChange={(event) =>
              handleRowsPerPageChange(event.target.value)
            }
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default PagedSortableTable;
