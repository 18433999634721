import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import BackOrders from "../backorders/BackOrders";
import OrderDetails from "../orders/OrderDetails";
import Orders from "../orders/Orders";
import InvoiceDetails from "../invoices/InvoiceDetails";
import Invoices from "../invoices/Invoices";
import CreditMemoDetails from "../creditmemos/CreditMemoDetails";
import CreditMemos from "../creditmemos/CreditMemos";
import CustomerDetails from "../customers/CustomerDetails";
import Customers from "../customers/Customers";
import Products from "../products/Products";
import Inventory from "../inventory/Inventory";
import UserEdit from "../users/UserEdit";
import Users from "../users/Users";
import BackOrderDetails from "../backorders/BackOrderDetails";
import RequirePerms from "./RequirePerms";
import ItemHistory from "../itemhistory/ItemHistory";
import RequireAuth from "./RequireAuth";
import Login from "../../Login";
import Layout from "./Layout/Layout";

// Creates routes and renders components based on routes. Also enforces authentication and permissions
function AppRoutes() {
  return (
    <Routes>
      {/* Login page */}
      {/* Nests all other routes */}
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <RequireAuth page="public">
            <Layout />
          </RequireAuth>
        }
      >
        {/* Nested routes */}
        {/* Dashboard (default route) */}
        <Route
          index
          element={
            <RequirePerms page="dashboard">
              <Dashboard />
            </RequirePerms>
          }
        />

        {/* Dashboard */}
        <Route
          path="dashboard"
          element={
            <RequirePerms page="dashboard">
              <Dashboard />
            </RequirePerms>
          }
        />

        {/* Backorder routes */}
        <Route
          path="backorders"
          element={
            <RequirePerms page="backorders">
              <BackOrders />
            </RequirePerms>
          }
        />
        <Route
          path="backorders/:id"
          element={
            <RequirePerms page="backorders">
              <BackOrderDetails />
            </RequirePerms>
          }
        />

        {/* Item History routes */}
        <Route
          path="itemhistory"
          element={
            <RequirePerms page="salesrep">
              <ItemHistory />
            </RequirePerms>
          }
        />

        {/* Order routes */}
        <Route
          path="orders"
          element={
            <RequirePerms page="orders">
              <Orders />
            </RequirePerms>
          }
        />
        <Route
          path="orders/:id"
          element={
            <RequirePerms page="orders">
              <OrderDetails />
            </RequirePerms>
          }
        />

        {/* Invoice routes */}
        <Route
          path="invoices"
          element={
            <RequirePerms page="invoices">
              <Invoices />
            </RequirePerms>
          }
        />
        <Route
          path="invoices/:id"
          element={
            <RequirePerms page="invoices">
              <InvoiceDetails />
            </RequirePerms>
          }
        />

        {/* Creditmemo routes */}
        <Route
          path="creditmemos"
          element={
            <RequirePerms page="creditMemos">
              <CreditMemos />
            </RequirePerms>
          }
        />
        <Route
          path="creditmemos/:id"
          element={
            <RequirePerms page="creditMemos">
              <CreditMemoDetails />
            </RequirePerms>
          }
        />

        {/* Customer routes */}
        <Route
          path="customers"
          element={
            <RequirePerms page="customers">
              <Customers />
            </RequirePerms>
          }
        />
        <Route
          path="customers/:id"
          element={
            <RequirePerms page="customers">
              <CustomerDetails />
            </RequirePerms>
          }
        />

        {/* Product routes */}
        <Route
          path="products"
          element={
            <RequirePerms page="products">
              <Products />
            </RequirePerms>
          }
        />
        {/* Inventory routes */}
        <Route
          path="inventory"
          element={
            <RequirePerms page="inventory">
              <Inventory />
            </RequirePerms>
          }
        />
        {/* User routes */}
        <Route
          path="users"
          element={
            <RequirePerms page="users">
              <Users />
            </RequirePerms>
          }
        />
        <Route
          path="users/:id"
          element={
            <RequirePerms page="users">
              <UserEdit />
            </RequirePerms>
          }
        />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
