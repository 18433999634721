export const backOrderInformation = (data) => {
  return [
    {
      name: "Delivery Number",
      content: data.deliveryNumber,
    },
    {
      name: "Customer Code",
      content: data.customerCode,
    },
    {
      name: "Shipping Address",
      content: data.shippingAddress,
    },
    {
      name: "Delivery Date",
      content: data.deliveryDate,
    },
    {
      name: "Order Total",
      content: "$" + (data.orderTotal * 1).toFixed(2),
    },
    {
      name: "Discount Percent",
      content: (data.discountPercent * 1).toFixed(2) + "%",
    },
  ];
};

export const backOrderItemsColumn = [
  {
    id: "itemCode",
    label: "Item",
  },
  {
    id: "warehouse",
    label: "Warehouse",
  },
  {
    id: "price",
    label: "Price",
  },
  {
    id: "ordered",
    label: "Ordered Quantity",
  },
  {
    id: "orderedTotal",
    label: "Ordered Total",
  },
  {
    id: "backOrdered",
    label: "Back Ordered Quantity",
  },
  {
    id: "backOrderedTotal",
    label: "Back Ordered Total",
  },
];
