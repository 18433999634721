import React from "react";
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from "@mui/material";
import Typography from "@mui/material/Typography";

// Renders a table with no extra features
function SimpleTable({ title, columns, details, children }) {
  return (
    <React.Fragment>
      {/* Table title */}
      <Toolbar>
        <Typography variant="h6">{title}</Typography>
      </Toolbar>
      <Divider />
      {/* Table */}
      <TableContainer>
        <Table>
          {/* Table head */}
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
              {/* {details ? <TableCell>Details</TableCell> : null} */}
            </TableRow>
          </TableHead>
          {/* Table body */}
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

export default SimpleTable;
