import React from "react";
import { ArrowForward } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";

// CSS styling
const useStyles = makeStyles({
  icon: {
    padding: 0,
  },
});

// Renders the row details button for tables
function DetailsButton({ onClick }) {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.icon}
      color="primary"
      onClick={onClick}
      size="large"
    >
      <ArrowForward sx={{ fontSize: 30 }} />
    </IconButton>
  );
}

export default DetailsButton;
