import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";

// Renders the backorder filter dialog box
function ItemHistoryDialog({ open, setOpen, onModalClose, onChangeFilters }) {
  // Filter parameters
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customer, setCustomer] = useState("");
  const [item, setItem] = useState("");

  useEffect(() => {
    setOpen(true);
  }, []);

  // Changes fetch URL based on entered filters and closes dialog
  const handleSubmit = (event) => {
    event.preventDefault();
    onChangeFilters(
      "&dateStart=" +
        startDate +
        "&dateEnd=" +
        endDate +
        "&customer=" +
        customer +
        "&item=" +
        item
    );
    onModalClose();
  };

  // Resets fetch URL with no filters
  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setCustomer("");
    setItem("");
  };

  // Rendered content
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onModalClose}
        maxWidth="xs"
        fullWidth
        aria-labelledby="order-dialog"
      >
        <form
          autoComplete="off"
          noValidate
          onSubmit={(event) => handleSubmit(event)}
        >
          <DialogTitle id="order-dialog">Add Filters</DialogTitle>
          <DialogContent dividers style={{ overflow: "hidden" }}>
            <Grid
              container
              spacing={3}
              alignItems="flex-start"
              justifyContent="center"
              direction="column"
            >
              {/* Date Selector Title Row */}
              <Grid container item spacing={5}>
                {/* Start date title */}
                <Grid item xs={6}>
                  <Typography component="h2" variant="h6">
                    Start Date
                  </Typography>
                </Grid>
                {/* End date title */}
                <Grid item xs={6}>
                  <Typography component="h3" variant="h6">
                    End Date
                  </Typography>
                </Grid>
              </Grid>
              {/* Date Textfield Row */}
              <Grid item container spacing={5}>
                <Grid item xs={6}>
                  {/* Start Date Textfield */}
                  <TextField
                    variant="outlined"
                    label="From"
                    type="date"
                    value={startDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => setStartDate(event.target.value)}
                    fullWidth
                  />
                </Grid>
                {/* End Date Textfield */}
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="To"
                    type="date"
                    value={endDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => setEndDate(event.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
              {/* Textfield titles */}
              <Grid container item spacing={5}>
                {/* Customer code title */}
                <Grid item xs={6}>
                  <Typography component="h4" variant="h6">
                    Customer Code
                  </Typography>
                </Grid>
                {/* Item code title */}
                <Grid item xs={6}>
                  <Typography component="h4" variant="h6">
                    Item Code
                  </Typography>
                </Grid>
              </Grid>
              {/* Textfields */}
              <Grid item container spacing={5}>
                {/* Customer Code Textfield */}
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Customer Code"
                    value={customer}
                    onChange={(event) => setCustomer(event.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Item Code"
                    value={item}
                    onChange={(event) => setItem(event.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          {/* Submit and Cancel Buttons */}
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={onModalClose}
            >
              Cancel
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClear}>
              Reset
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Apply
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

export default ItemHistoryDialog;
