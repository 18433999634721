import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import useUserForm from "../../helpers/hooks/useUserForm";
import UserForm from "./UserForm";
import { emptyUser } from "../../helpers/constants/constants";

// Renders dialog box for creating new user
function NewUserDialog({ open, setOpen }) {
  const {
    username,
    setUsername,
    password,
    setPassword,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    userType,
    setUserType,
    salesRepCode,
    setSalesRepCode,
    error,
    setError,
    active,
    setActive,
    warehouse,
    setWarehouse,
    handleSubmit,
  } = useUserForm(setOpen, emptyUser, "create");

  return (
    <React.Fragment>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <form
          autoComplete="off"
          noValidate
          onSubmit={(event) => handleSubmit(event)}
        >
          <DialogTitle>Add New User</DialogTitle>
          <DialogContent dividers style={{ overflow: "hidden" }}>
            {/* User form component */}
            <UserForm
              username={username}
              setUsername={setUsername}
              password={password}
              setPassword={setPassword}
              email={email}
              setEmail={setEmail}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              userType={userType}
              setUserType={setUserType}
              salesRepCode={salesRepCode}
              setSalesRepCode={setSalesRepCode}
              error={error}
              errorMessage={"User Creation Failed"}
              active={active}
              setActive={setActive}
              warehouse={warehouse}
              setWarehouse={setWarehouse}
              method={"create"}
            />
          </DialogContent>
          {/* Submit and Cancel Buttons */}
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

export default NewUserDialog;
