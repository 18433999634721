import React, { useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import EditButton from "../general/EditButton";
import DeleteButton from "../general/DeleteButton";
import NewUserDialog from "./NewUserDialog";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../helpers/axios";

// Renders user data into table rows
function UserList({ data }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const typeDict = { 1: "Admin", 2: "Sales Rep", 3: "Warehouse" };

  // Edit button; redirects to edit page
  const handleEdit = (user) => {
    navigate("/users/" + user + "/");
  };

  const handleDelete = (user) => {
    // Soft delete (sets active to false)
    // axiosInstance
    //     .patch(apiUrl + "/users/" + user + "/", {is_active: 0})
    //     .then((response) => {
    //       navigate("/users")
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });

    // Hard delete (removes user from database)
    axiosInstance
      .delete("/users/" + user + "/")
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      {data.map((user) => (
        <TableRow key={user.username} hover>
          <TableCell>{user.username}</TableCell>
          <TableCell>{user.first_name}</TableCell>
          <TableCell>{user.last_name}</TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>{typeDict[user.user_type]}</TableCell>
          <TableCell>{user.sales_rep_code}</TableCell>
          <TableCell>{user.warehouse}</TableCell>
          <TableCell>{user.is_active ? "Yes" : "No"}</TableCell>
          <TableCell>
            <EditButton onClick={() => handleEdit(user.username)} />
            <NewUserDialog open={open} setOpen={setOpen} method="patch" />
            <DeleteButton onClick={() => handleDelete(user.username)} />
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

export default UserList;
