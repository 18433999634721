import { Typography } from "@mui/material";
import React, { useContext } from "react";
import { UserContext } from "../../helpers/UserContext";

function Greeting() {
  // User info
  const { user } = useContext(UserContext);

  // Determine greeting based on time
  const today = new Date();
  const hour = today.getHours();
  var greeting = "Good Evening,";

  if (hour < 12) {
    greeting = "Good Morning,";
  } else if (hour < 18) {
    greeting = "Good Afternoon,";
  }

  return (
    <Typography sx={{ fontWeight: "bold" }} variant="h5">
      {`${greeting} ${user.first_name} ${user.last_name}`}
    </Typography>
  );
}

export default Greeting;
