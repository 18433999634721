import React, { useState } from "react";
import PageTitle from "../general/PageTitle";
import DashboardLink from "../general/links/DashboardLink";
import CurrentPage from "../general/links/CurrentPage";
import PagedSortableTable from "../table/PagedSortableTable";
import { userColumns } from "../../helpers/constants/constants";
import UserList from "./UserList";
import { Button, Grid, TableCell } from "@mui/material";
import UserDialog from "./UserDialog";
import NewUserDialog from "./NewUserDialog";

// Primary user component. Renders all user content
function Users() {
  const [open, setOpen] = useState(false);

  return (
    <div className="users">
      <React.Fragment>
        <Grid container justifyContent="space-between">
          {/* Title */}
          <Grid item>
            <PageTitle title={"Users"} />
          </Grid>
          {/* Add user button */}
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(true)}
            >
              Add New User
            </Button>
            {/* New user dialog */}
            <NewUserDialog open={open} setOpen={setOpen} />
          </Grid>
        </Grid>
        {/* Link */}
        <div style={{ display: "flex" }}>
          <DashboardLink />
          <CurrentPage currentPage={"Users"} />
        </div>
        {/* Table */}
        <PagedSortableTable
          baseUrl={"/users"}
          columns={userColumns}
          title="Users"
          BodyComponent={UserList}
          DialogComponent={UserDialog}
          extra={<TableCell>Actions</TableCell>}
        />
      </React.Fragment>
    </div>
  );
}

export default Users;
