import React from "react";
import { TableCell, TableRow } from "@mui/material";
import DetailsButton from "../general/DetailsButton";
import { useNavigate } from "react-router-dom";

// Renders backorder data into table rows
function BackOrderList({ data }) {
  const navigate = useNavigate();

  // Details button; redirects to order page
  const handleClick = (deliveryId) => {
    navigate("/backorders/" + deliveryId);
  };

  return (
    <React.Fragment>
      {data.map((backOrder) => (
        <TableRow key={backOrder.deliveryId} hover>
          <TableCell>{backOrder.deliveryNumber}</TableCell>
          <TableCell>{backOrder.deliveryDate}</TableCell>
          <TableCell>{backOrder.customerCode}</TableCell>
          <TableCell>{backOrder.shippingAddressName}</TableCell>
          <TableCell>{"$" + (backOrder.orderTotal * 1).toFixed(2)}</TableCell>
          <TableCell>
            {(backOrder.discountPercent * 1).toFixed(2) + "%"}
          </TableCell>
          <TableCell>
            <DetailsButton onClick={() => handleClick(backOrder.deliveryId)} />
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

export default BackOrderList;
