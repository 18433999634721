import React from 'react';
import InvoiceDialog from "../invoices/InvoiceDialog";

// Renders credit memo filter dialog box.
function CreditMemoDialog({ open, onModalClose, onChangeFilters }) {
    // Simply renders the InvoiceDialog component as they are identical
    return (
        <InvoiceDialog open={open} onModalClose={onModalClose} onChangeFilters={onChangeFilters}/>
    );
}

export default CreditMemoDialog;