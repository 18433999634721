import React, { useContext, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./helpers/axios";
import { UserContext } from "./helpers/UserContext";

// CSS styling
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// Renders the login page
function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState(false);
  const { user, setUser } = useContext(UserContext);

  // Delete all stored user information upon rendering
  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setUser(null);
  }, []);

  // Requests a token from the server based on login credentials
  const handleSubmit = (event) => {
    event.preventDefault();

    axiosInstance
      .post("/token/", {
        username: username,
        password: password,
      })
      .then((response) => {
        axiosInstance.defaults.headers["Authorization"] =
          "JWT " + response.data.access;
        // Store token in local storage
        localStorage.setItem("access_token", response.data.access);
        localStorage.setItem("refresh_token", response.data.refresh);
      })
      .then(() => {
        axiosInstance
          .get("/users/" + username)
          .then((response) => {
            // Store general user information in context
            setUser(response.data);
          })
          .then(() => {
            setLoginFailed(false);
            navigate("/dashboard");
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch(() => {
        setLoginFailed(true);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* Login logo */}
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        {/* Login title */}
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          className={classes.form}
          onSubmit={(event) => handleSubmit(event)}
          noValidate
        >
          {/* Username textfield */}
          <TextField
            variant="outlined"
            margin="normal"
            autoComplete="false"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoFocus
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            error={loginFailed && !username}
          />
          {/* Password textfield */}
          <TextField
            variant="outlined"
            margin="normal"
            autoComplete="false"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            error={loginFailed && !password}
          />
          {/* Indicate error if login failed */}
          {loginFailed && (
            <Typography color="error">Invalid Credentials</Typography>
          )}
          {/* Login button */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default Login;
