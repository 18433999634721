import { Card, Grid, Typography } from "@mui/material";
import React from "react";

function InfoCard({ data, subtitle, size = 10, background, children }) {
  return (
    <Card sx={{ backgroundColor: background, height: 72 }}>
      <Grid container sx={{ padding: 2 }} alignItems="center">
        <Grid item xs={size} justifyContent="right">
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {subtitle}
          </Typography>

          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", fontSize: { xs: 20 } }}
          >
            {data}
          </Typography>
        </Grid>
        <Grid item xs={12 - size}>
          {children}
        </Grid>
      </Grid>
    </Card>
  );
}

export default InfoCard;
