import { useContext, useEffect, useState } from "react";
import axiosInstance from "../axios";
import { emptyUser } from "../constants/constants";
import { UserContext } from "../UserContext";

function useGetUser() {
  const { user, setUser } = useContext(UserContext);
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    axiosInstance
      .get("/user")
      .then((response) => {
        setUser(response.data);
        setIsPending(false);
      })
      .catch((error) => {
        console.log(error);
        setUser(emptyUser);
        setIsPending(false);
      });
  }, []);

  return { isPending, setIsPending };
}

export default useGetUser;
