import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Box, Button, Container, Hidden } from "@mui/material";
import { useNavigate, Outlet } from "react-router-dom";
import { ExitToApp } from "@mui/icons-material";
import axiosInstance from "../../../helpers/axios";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import useGetUser from "../../../helpers/hooks/useGetUser";
import Loading from "../Loading";
import SideDrawer from "./SideDrawer";

// Contains app components that are present in virtually every page. Includes the side drawer and top app bar

// The Layout component
function Layout({ children }) {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { isPending } = useGetUser();

  // Logs the user out; removes the token from local storage requiring another login to access the app
  const handleLogout = async () => {
    const response = await axiosInstance.post("/logout/", {
      refresh_token: localStorage.getItem("refresh_token"),
    });
    navigate("/login");
    axiosInstance.defaults.headers["Authorization"] = null;

    return response;
  };

  if (isPending) return <Loading />;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* App bar */}
      <AppBar position="fixed" sx={{ zIndex: 1251 }} color="primary">
        <Toolbar>
          {/* Open and close drawer */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => {
              setMobileOpen(!mobileOpen);
            }}
            sx={{ display: { xl: "none" }, marginRight: 2 }}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          {/* App bar title */}
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Annie Sales Portal
          </Typography>
          {/* Logout button */}
          <Button
            color="secondary"
            variant="contained"
            sx={{ margin: 1 }}
            startIcon={<ExitToApp />}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      {/* Side Drawer */}
      <SideDrawer mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      {/* Page content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          maxWidth: "100%",
        }}
      >
        <Hidden lgDown>
          <Box
            sx={{
              flexGrow: 1,
              marginLeft: 5,
              marginRight: 5,
              paddingTop: 6,
              paddingBottom: 2,
            }}
          >
            <Toolbar />
            {children}
            <Outlet />
          </Box>
        </Hidden>
        <Hidden lgUp>
          <Container sx={{ flexGrow: 1, paddingTop: 6, paddingBottom: 2 }}>
            <Toolbar />
            {children}
            <Outlet />
          </Container>
        </Hidden>
      </Box>
    </Box>
  );
}

export default Layout;
