import React from "react";
import Loading from "../general/Loading";
import SimpleTable from "../table/SimpleTable";
import { Paper } from "@mui/material";
import OrderList from "../orders/OrderList";
import { orderColumns } from "../../helpers/constants/constants";
import { useNavigate } from "react-router-dom";
import SeeAllLink from "./SeeAllLink";
import useQueryPaged from "../../helpers/hooks/useQueryPaged";

// Renders recent orders paper
function RecentOrders() {
  const { data, isLoading } = useQueryPaged(
    "/orders",
    0,
    10,
    "",
    "asc",
    "",
    ""
  );
  const navigate = useNavigate();

  // See all orders link; redirects to orders page
  const handleClick = () => {
    navigate("/orders");
  };

  // Render loading if data not done fetching

  if (isLoading) return <Loading />;

  return (
    <Paper variant="outlined">
      {/* Table */}
      <SimpleTable
        title="Your Recent Orders"
        columns={orderColumns}
        details={true}
      >
        <OrderList data={data.results} />
      </SimpleTable>
      {/* Link */}
      <SeeAllLink text="See all orders" onClick={handleClick} />
    </Paper>
  );
}

export default RecentOrders;
