import React from "react";
import { Link, TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DetailsButton from "../general/DetailsButton";
import DownloadButton from "../general/DownloadButton";

// Renders customer data into table rows
function CustomerList({ data }) {
  const navigate = useNavigate();

  // Details button, redirects to detail page
  const handleClick = (customerCode) => {
    navigate("/customers/" + customerCode);
  };

  return (
    <React.Fragment>
      {data.map((customer) => (
        <TableRow hover key={customer.customerCode}>
          <TableCell>{customer.customerCode}</TableCell>
          <TableCell>{customer.customerName}</TableCell>
          <TableCell>
            <Link
              href={`https://www.google.com/maps/place/${customer.billToAddress} ${customer.city} ${customer.state} ${customer.zipcode}`}
              target="_blank"
            >
              {customer.billToAddress}
            </Link>
          </TableCell>
          <TableCell>{customer.paymentGroup}</TableCell>
          <TableCell>{customer.groupType}</TableCell>
          <TableCell>{(customer.discountPer * 100).toFixed(2) + "%"}</TableCell>
          <TableCell>
            <DownloadButton
              fileInfo={{ customerCode: customer.customerCode }}
              fileType={"statement"}
            />
          </TableCell>
          <TableCell>
            <DetailsButton onClick={() => handleClick(customer.customerCode)} />
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

export default CustomerList;
