import React from "react";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

// CSS styling
const useStyles = makeStyles({
  link: {
    marginTop: 10,
    marginBottom: 20,
  },
});

// Renders general link for page navigation
function NavigationLink({ name, url }) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {/* Link */}
      <Link
        className={classes.link}
        component="button"
        onClick={() => navigate(url)}
      >
        <Typography variant="subtitle2" gutterBottom noWrap>
          {name}
        </Typography>
      </Link>
      {/* Arrow icon */}
      <KeyboardArrowRightIcon style={{ position: "relative", top: "8px" }} />
    </React.Fragment>
  );
}

export default NavigationLink;
