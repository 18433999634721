import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, Paper } from "@mui/material";
import PageTitle from "../general/PageTitle";
import Loading from "../general/Loading";
import NavigationLink from "../general/links/NavigationLink";
import DashboardLink from "../general/links/DashboardLink";
import CurrentPage from "../general/links/CurrentPage";
import useUserForm from "../../helpers/hooks/useUserForm";
import UserForm from "./UserForm";
import useQueryUrl from "../../helpers/hooks/useQueryUrl";
import { emptyUser } from "../../helpers/constants/constants";

// Renders the user edit page
function UserEdit() {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const { data, isLoading } = useQueryUrl(`/users/${id}/`);
  const {
    username,
    setUsername,
    password,
    setPassword,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    userType,
    setUserType,
    salesRepCode,
    setSalesRepCode,
    error: formError,
    setError,
    active,
    setActive,
    handleSubmit,
    warehouse,
    setWarehouse,
  } = useUserForm(setOpen, data ? data : emptyUser, "edit");

  if (isLoading) return <Loading />;

  return (
    <React.Fragment>
      <PageTitle title={`Edit User ${id}`} />
      <div style={{ display: "flex" }}>
        <DashboardLink />
        <NavigationLink name="Users" url="/users" />
        <CurrentPage currentPage={`Edit ${id}`} />
      </div>
      <form
        noValidate
        autoComplete="off"
        onSubmit={(event) => handleSubmit(event)}
      >
        <Paper variant="outlined" style={{ padding: 30 }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              {/* User form */}
              <UserForm
                username={username}
                setUsername={setUsername}
                password={password}
                setPassword={setPassword}
                email={email}
                setEmail={setEmail}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                userType={userType}
                setUserType={setUserType}
                salesRepCode={salesRepCode}
                setSalesRepCode={setSalesRepCode}
                error={formError}
                errorMessage={"User Edit Failed"}
                active={active}
                setActive={setActive}
                warehouse={warehouse}
                setWarehouse={setWarehouse}
                method={"edit"}
              />
            </Grid>
            <Grid item>
              {/* Submit button */}
              <Button type="submit" color="primary" variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </React.Fragment>
  );
}

export default UserEdit;
