import React, { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";

// Renders content related to table filtering
function TableFilters({
  setSearch,
  filters,
  title,
  onChangeFilters,
  FilterDialog,
}) {
  const [open, setOpen] = useState(false);

  // Handles search
  const handleSearch = (event) => {
    event.preventDefault();
    setSearch(event.target.value);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* Grid Container for buttons */}
      <Grid item xs={7} md={9} alignItems="flex-end">
        {/* Add filters button */}

        <Button
          sx={{ marginRight: 1.5 }}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            setOpen(true);
          }}
        >
          {/* <FilterAltIcon /> */}
          Filters
        </Button>
        {/* Filter dialog box */}
        <FilterDialog
          open={open}
          onModalClose={handleModalClose}
          filters={filters}
          onChangeFilters={onChangeFilters}
          setOpen={setOpen}
        />

        {/* Clear filters button */}
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => onChangeFilters("")}
        >
          {/* <ClearIcon /> */}
          Clear
        </Button>
      </Grid>
      {/* Search bar */}
      <Grid item xs={12} md={3} direction="row-reverse">
        <TextField
          sx={{ width: 1 }}
          id="search"
          variant="outlined"
          label={`Search ${title}`}
          onChange={(event) => handleSearch(event)}
        />
      </Grid>
    </React.Fragment>
  );
}

export default TableFilters;
