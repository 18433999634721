import React from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import DetailsCard from "../general/DetailsCard";
import PageTitle from "../general/PageTitle";
import PagedTable from "../table/PagedTable";
import CustomerDetailsList from "./CustomerDetailsList";
import Loading from "../general/Loading";
import usePagination from "../../helpers/hooks/usePagination";
import {
  customerContact,
  customerFinances,
  addressColumns,
} from "../../helpers/constants/customerDetailsConstants";
import NavigationLink from "../general/links/NavigationLink";
import DashboardLink from "../general/links/DashboardLink";
import CurrentPage from "../general/links/CurrentPage";
import useQueryUrl from "../../helpers/hooks/useQueryUrl";

// Renders the detail page for individual customers
function CustomerDetails() {
  const { id } = useParams();
  const { currentPage, pageSize, handlePageChange, handleRowsPerPageChange } =
    usePagination();
  const { data, isLoading } = useQueryUrl("/customers/" + id);

  // Render loading if data not done fetching
  if (isLoading) return <Loading />;

  return (
    <React.Fragment>
      {/* Title */}
      <PageTitle title={`Customer ${id} Details`} />
      {/* Link */}
      <div style={{ display: "flex" }}>
        <DashboardLink />
        <NavigationLink name="Customers" url="/customers" />
        <CurrentPage currentPage={`${id} Details`} />
      </div>
      {/* Card layout */}
      <Grid container spacing={3}>
        {/* Contact info */}
        <Grid item xs={12} md={6}>
          <DetailsCard
            title="Customer Information"
            data={customerContact(data)}
          />
        </Grid>
        {/* Address table */}
        <Grid item xs={12} md={6}>
          <PagedTable
            title="Addresses"
            columns={addressColumns}
            length={data.addresses.length}
            currentPage={currentPage}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          >
            <CustomerDetailsList
              addresses={data.addresses}
              currentPage={currentPage}
              pageSize={pageSize}
            />
          </PagedTable>
        </Grid>
        {/* Financial info */}
        <Grid item xs={12} md={6}>
          <DetailsCard
            title="Customer Finances"
            data={customerFinances(data)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CustomerDetails;
