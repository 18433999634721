import { makeStyles } from "@mui/styles";
import React from "react";
import { TableCell, TableRow } from "@mui/material";

// CSS styling
const useStyles = makeStyles((theme) => ({
  row: {
    backgroundColor: theme.palette.common.white,
  },
}));

// Renders inventory data into table rows
function InventoryList({ data }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {data.map((inventory) => (
        <TableRow hover>
          <TableCell className={classes.row}>{inventory.warehouse}</TableCell>
          <TableCell className={classes.row}>{inventory.itemCode}</TableCell>
          {/* <TableCell className={classes.row}>{inventory.itemBrand}</TableCell>
          <TableCell className={classes.row}>
            {inventory.itemCategory}
          </TableCell>
          <TableCell className={classes.row}>{inventory.itemLine}</TableCell> */}
          <TableCell className={classes.row}>
            {inventory.itemDescription}
          </TableCell>
          <TableCell className={classes.row}>
            {(inventory.inStock * 1).toFixed(0)}
          </TableCell>
          <TableCell className={classes.row}>
            {(inventory.committed * 1).toFixed(0)}
          </TableCell>
          <TableCell className={classes.row}>
            {(inventory.available * 1).toFixed(0)}
          </TableCell>
          <TableCell className={classes.row}>
            {(inventory.arrivalQuantity * 1).toFixed(0)}
          </TableCell>
          <TableCell className={classes.row}>
            {inventory.eta ? inventory.eta : ""}
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

export default InventoryList;
