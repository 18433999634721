import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

// CSS styling
const useStyles = makeStyles({
  warehouse: {
    width: 100,
  },
  stock: {
    width: 300,
  },
});

// Renders the inventory filter dialog box
function InventoryDialog({ open, onModalClose, onChangeFilters }) {
  const classes = useStyles();

  // Filter parameters
  const [warehouse, setWarehouse] = useState("");
  const [item, setItem] = useState("");
  const [itemStart, setItemStart] = useState("");
  const [itemEnd, setItemEnd] = useState("");
  const [isInStock, setIsInStock] = useState("");

  // Changes fetch URL based on entered filters and closes dialog
  const handleSubmit = (event) => {
    event.preventDefault();
    onChangeFilters(
      `&warehouse=${warehouse}&item=${item}&isInStock=${isInStock}&itemStart=${itemStart}&itemEnd=${itemEnd}`
    );
    onModalClose();
  };

  // Resets fetch URL with no filters
  const handleClear = () => {
    setWarehouse("");
    setItem("");
    setItemStart("");
    setItemEnd("");
    setIsInStock("");
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onModalClose}
        maxWidth="xs"
        fullWidth
        aria-labelledby="product-dialog"
      >
        <form
          autoComplete="off"
          noValidate
          onSubmit={(event) => handleSubmit(event)}
        >
          <DialogTitle id="product-dialog">Add Filters</DialogTitle>
          <DialogContent dividers style={{ overflow: "hidden" }}>
            <Grid
              container
              spacing={3}
              alignItems="flex-start"
              justifyContent="center"
              direction="column"
            >
              {/* Textfield Titles */}
              <Grid item container spacing={10}>
                {/* Warehouse Title */}
                <Grid item xs={6}>
                  <Typography component="h2" variant="h6">
                    Warehouse
                  </Typography>
                </Grid>
                {/* Item Number Title */}
                <Grid item xs={6}>
                  <Typography component="h3" variant="h6">
                    Item Number
                  </Typography>
                </Grid>
              </Grid>
              {/* Textfields */}
              <Grid item container spacing={10}>
                {/* Warehouse Textfield */}
                <Grid item xs={6}>
                  <TextField
                    className={classes.warehouse}
                    label="Warehouse"
                    variant="outlined"
                    onChange={(event) => setWarehouse(event.target.value)}
                    value={warehouse}
                    select
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="01">1 - PA</MenuItem>
                    <MenuItem value="02">2 - GA</MenuItem>
                    <MenuItem value="03">3 - CA</MenuItem>
                  </TextField>
                </Grid>
                {/* Item Number Textfield */}
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Item Number"
                    value={item}
                    onChange={(event) => setItem(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={10}>
                {/* Warehouse Title */}
                <Grid item xs={6}>
                  <Typography component="h2" variant="h6">
                    Item Start
                  </Typography>
                </Grid>
                {/* Item Number Title */}
                <Grid item xs={6}>
                  <Typography component="h3" variant="h6">
                    Item End
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container spacing={10}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Item Start"
                    value={itemStart}
                    onChange={(event) => setItemStart(event.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Item Start"
                    value={itemEnd}
                    onChange={(event) => setItemEnd(event.target.value)}
                  />
                </Grid>
              </Grid>
              {/* Stock Title */}
              <Grid item xs={12}>
                <Typography component="h4" variant="h6">
                  Stock
                </Typography>
              </Grid>
              {/* Stock selector */}
              <Grid item xs={12}>
                <TextField
                  className={classes.stock}
                  label="Stock"
                  variant="outlined"
                  onChange={(event) => setIsInStock(event.target.value)}
                  value={isInStock}
                  select
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="0">Out of Stock</MenuItem>
                  <MenuItem value="1">In Stock</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          {/* Submit and Cancel Buttons */}
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={onModalClose}
            >
              Cancel
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClear}>
              Reset
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Apply
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

export default InventoryDialog;
