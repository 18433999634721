import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PeopleIcon from "@mui/icons-material/People";
import ListAltIcon from "@mui/icons-material/List";
import React from "react";
import { createContext } from "react";
import {
  AccountBalance,
  AccountBox,
  History,
  HourglassFull,
  Receipt,
} from "@mui/icons-material";

export const ApiUrlContext = createContext("http://192.168.1.146:8000/api");

export const emptyUser = {
  username: "",
  email: "",
  first_name: "",
  last_name: "",
  user_type: "",
  sales_rep_code: "",
  warehouse: "",
};

export const pagePermissions = {
  public: ["warehouse", "salesRep"],
  salesrep: ["salesRep"],
  dashboard: ["salesRep"],
  backorders: ["salesRep"],
  orders: ["salesRep"],
  invoices: ["salesRep"],
  creditMemos: ["salesRep"],
  customers: ["salesRep"],
  products: ["warehouse", "salesRep"],
  inventory: ["warehouse", "salesRep"],
  users: [],
};

export const userDictionary = {
  1: {
    type: "admin",
    redirect: "/dashboard",
  },
  2: {
    type: "salesRep",
    redirect: "/dashboard",
  },
  3: {
    type: "warehouse",
    redirect: "/inventory",
  },
};

export const menuItems = [
  {
    text: "Dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
    permissions: pagePermissions.dashboard,
  },
  {
    text: "Back Orders",
    icon: <HourglassFull />,
    path: "/backorders",
    permissions: pagePermissions.backorders,
  },
  {
    text: "Item Sales History",
    icon: <History />,
    path: "/itemhistory",
    permissions: pagePermissions.salesrep,
  },
  {
    text: "Orders",
    icon: <ShoppingCartIcon />,
    path: "/orders",
    permissions: pagePermissions.orders,
  },
  {
    text: "Invoices",
    icon: <Receipt />,
    path: "/invoices",
    permissions: pagePermissions.invoices,
  },
  {
    text: "Credit Memos",
    icon: <AccountBalance />,
    path: "/creditmemos",
    permissions: pagePermissions.creditMemos,
  },
  {
    text: "Customers",
    icon: <PeopleIcon />,
    path: "/customers",
    permissions: pagePermissions.customers,
  },
  {
    text: "Inventory",
    icon: <AssignmentIcon />,
    path: "/inventory",
    permissions: pagePermissions.inventory,
  },
  {
    text: "Products",
    icon: <ListAltIcon />,
    path: "/products",
    permissions: pagePermissions.products,
  },
  {
    text: "Users",
    icon: <AccountBox />,
    path: "/users",
    permissions: pagePermissions.users,
  },
];

export const orderColumns = [
  {
    id: "orderNumber",
    label: "Order Number",
  },
  {
    id: "orderDate",
    label: "Order Date",
  },
  {
    id: "customerCode",
    label: "Customer Code",
  },
  {
    id: "shippingAddressName",
    label: "Address Name",
  },
  {
    id: "total",
    label: "Total",
  },
  {
    id: "",
    label: "Details",
  },
  {
    id: "orderStatus",
    label: "Status",
  },
];

export const invoiceColumns = [
  {
    id: "orderNumber",
    label: "Invoice Number",
  },
  {
    id: "orderDate",
    label: "Order Date",
  },
  {
    id: "customerCode",
    label: "Customer Code",
  },
  {
    id: "shippingAddressName",
    label: "Address Name",
  },
  {
    id: "trackingNumber",
    label: "Tracking",
  },
  {
    id: "total",
    label: "Total",
  },
  {
    id: "",
    label: "Details",
  },
  {
    id: "daysOpen",
    label: "Day Open",
  },
  {
    id: "orderStatus",
    label: "Status",
  },
];

export const productColumns = [
  {
    id: "productCode",
    label: "ID",
  },
  {
    id: "productName",
    label: "Item Name",
  },
  {
    id: "barcodeNumber",
    label: "Barcode",
  },
  {
    id: "unitsPerInner",
    label: "Units Per Inner",
  },
  {
    id: "unitsPerCarton",
    label: "Units Per Carton",
  },
  {
    id: "retailPrice",
    label: "Retail Price",
  },
  {
    id: "quantityPrice",
    label: "Quantity Price",
  },
  {
    id: "wholesalePrice",
    label: "Wholesale Price",
  },
];

export const inventoryColumns = [
  {
    id: "warehouse",
    label: "Warehouse",
  },
  {
    id: "itemCode",
    label: "Item Number",
  },
  // {
  //   id: "itemBrand",
  //   label: "Brand",
  // },
  // {
  //   id: "itemCategory",
  //   label: "Category",
  // },
  // {
  //   id: "itemLine",
  //   label: "Line",
  // },
  {
    id: "itemDescription",
    label: "Description",
  },
  {
    id: "inStock",
    label: "In Stock",
  },
  {
    id: "committed",
    label: "Committed",
  },
  {
    id: "available",
    label: "Available",
  },
  {
    id: "arrivalQuantity",
    label: "Arriving Quantity",
  },
  {
    id: "eta",
    label: "ETA",
  },
];

export const customerColumns = [
  {
    id: "customerCode",
    label: "ID",
  },
  {
    id: "customerName",
    label: "Customer Name",
  },
  {
    id: "billToAddress",
    label: "Address",
  },
  {
    id: "paymentGroup",
    label: "Payment Group",
  },
  {
    id: "groupType",
    label: "Group Name",
  },
  {
    id: "discountPer",
    label: "Discount %",
  },
  {
    id: "",
    label: "Statement",
  },
  {
    id: "",
    label: "Details",
  },
];

export const customerDetailColumns = [
  {
    id: "customerCode",
    label: "ID",
  },
  {
    id: "addressName",
    label: "Address Name",
  },
  {
    id: "warehouse",
    label: "Warehouse",
  },
  {
    id: "shippingAddress",
    label: "Shipping Address",
  },
];

export const backOrderColumns = [
  {
    id: "deliveryNumber",
    label: "Delivery Number",
  },
  {
    id: "deliveryDate",
    label: "Order Date",
  },
  {
    id: "customerCode",
    label: "Customer Code",
  },
  {
    id: "shippingAddressName",
    label: "Address Name",
  },
  {
    id: "orderTotal",
    label: "Total",
  },
  {
    id: "discountPercent",
    label: "Discount %",
  },
  {
    id: "",
    label: "Details",
  },
];

export const userColumns = [
  {
    id: "username",
    label: "Username",
  },
  {
    id: "first_name",
    label: "First Name",
  },
  {
    id: "last_name",
    label: "Last Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "user_type",
    label: "Type",
  },
  {
    id: "sales_rep_code",
    label: "SalesRep Code",
  },
  {
    id: "warehouse",
    label: "Warehouse",
  },
  {
    id: "is_active",
    label: "Active",
  },
  {
    id: "",
    label: "Actions",
  },
];

export const itemHistoryColumns = [
  {
    id: "orderId",
    label: "Order Number",
  },
  {
    id: "itemCode",
    label: "Item",
  },
  {
    id: "customerCode",
    label: "Customer Code",
  },
  {
    id: "addressName",
    label: "Customer Name",
  },
  {
    id: "orderDate",
    label: "Order Date",
  },
  {
    id: "price",
    label: "Price",
  },
  {
    id: "quantity",
    label: "Quantity",
  },
  {
    id: "itemTotal",
    label: "Total",
  },
];
