import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

// CSS styling
const useStyles = makeStyles({
  link: {
    marginTop: 10,
    marginBottom: 20,
  },
});

// Renders current page text for page navigation
function CurrentPage({ currentPage }) {
  const classes = useStyles();
  return (
    <Typography
      className={classes.link}
      color="primary"
      variant="subtitle2"
      gutterBottom
      noWrap
    >
      {currentPage}
    </Typography>
  );
}

export default CurrentPage;
