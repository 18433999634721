import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

// CSS styling
const useStyles = makeStyles({
  text: {
    color: blueGrey[600],
  },
});

// Renders customer details data into table rows
function CustomerDetailsList({ addresses, currentPage, pageSize }) {
  const classes = useStyles();
  const endIndex =
    pageSize === -1 ? addresses.length : currentPage * pageSize + pageSize;
  return (
    <React.Fragment>
      {addresses.slice(currentPage * pageSize, endIndex).map((address) => (
        <TableRow key={address.addressName}>
          <TableCell>
            <Typography className={classes.text} variant="body2">
              {address.warehouse}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.text} variant="body2">
              {address.addressName}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.text} variant="body2">
              {address.shippingAddress}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

export default CustomerDetailsList;
