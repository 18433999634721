import React from "react";
import PagedSortableTable from "../table/PagedSortableTable";
import OrderList from "./OrderList";
import { orderColumns } from "../../helpers/constants/constants";
import OrderDialog from "./OrderDialog";
import PageTitle from "../general/PageTitle";
import DashboardLink from "../general/links/DashboardLink";
import CurrentPage from "../general/links/CurrentPage";
import { TableCell } from "@mui/material";

// Primary orders component. Renders all order content
function Orders() {
  return (
    <React.Fragment>
      {/* Title */}
      <PageTitle title={"Your Orders"} />
      {/* Link */}
      <div style={{ display: "flex" }}>
        <DashboardLink />
        <CurrentPage currentPage={"Orders"} />
      </div>
      {/* Table */}
      <PagedSortableTable
        baseUrl={"/orders"}
        columns={orderColumns}
        title="Your Orders"
        BodyComponent={OrderList}
        DialogComponent={OrderDialog}
        extra={<TableCell>Details</TableCell>}
      />
    </React.Fragment>
  );
}

export default Orders;
