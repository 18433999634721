import { Drawer, Hidden } from "@mui/material";
import React from "react";
import DrawerContent from "./DrawerContent";

function SideDrawer({ mobileOpen, setMobileOpen }) {
  return (
    <React.Fragment>
      <Hidden xlUp>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={() => {
            setMobileOpen(!mobileOpen);
          }}
          PaperProps={{ sx: { width: 270 } }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerContent
            mobileOpen={mobileOpen}
            setMobileOpen={setMobileOpen}
          />
        </Drawer>
      </Hidden>
      {/* Permanent drawer for large screen size*/}
      <Hidden xlDown>
        <Drawer
          sx={{ flexShrink: 0, width: 270 }}
          variant="permanent"
          PaperProps={{ sx: { width: 270 } }}
        >
          <DrawerContent
            mobileOpen={mobileOpen}
            setMobileOpen={setMobileOpen}
          />
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
}

export default SideDrawer;
