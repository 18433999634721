import React from "react";
import PagedSortableTable from "../table/PagedSortableTable";
import { invoiceColumns } from "../../helpers/constants/constants";
import InvoiceList from "./InvoiceList";
import InvoiceDialog from "./InvoiceDialog";
import PageTitle from "../general/PageTitle";
import DashboardLink from "../general/links/DashboardLink";
import CurrentPage from "../general/links/CurrentPage";
import { TableCell } from "@mui/material";

// Primary invoice component. Renders all invoice content
function Invoices() {
  return (
    <React.Fragment>
      {/* Title */}
      <PageTitle title={"Your Invoices"} />
      {/* Link */}
      <div style={{ display: "flex" }}>
        <DashboardLink />
        <CurrentPage currentPage={"Invoices"} />
      </div>
      {/* Table */}
      <PagedSortableTable
        baseUrl={"/invoices"}
        columns={invoiceColumns}
        title="Your Invoices"
        BodyComponent={InvoiceList}
        DialogComponent={InvoiceDialog}
        extra={<TableCell>Details</TableCell>}
      />
    </React.Fragment>
  );
}

export default Invoices;
