import React, { useEffect } from "react";
import { TableCell, TableRow } from "@mui/material";
import DetailsButton from "../general/DetailsButton";
import { useNavigate } from "react-router-dom";

// Renders backorder data into table rows
function ItemHistoryList({ data }) {
  const navigate = useNavigate();

  // Details button; redirects to order page
  const handleClick = (orderId) => {
    navigate("/itemhistory/" + orderId);
  };

  return (
    <React.Fragment>
      {data &&
        data.map((order) => (
          <TableRow hover>
            <TableCell>{order.orderId}</TableCell>
            <TableCell>{order.itemCode}</TableCell>
            <TableCell>{order.customerCode}</TableCell>
            <TableCell>{order.addressName}</TableCell>
            <TableCell>{order.orderDate}</TableCell>
            <TableCell>{"$" + (order.price * 1).toFixed(2)}</TableCell>
            <TableCell>{(order.quantity * 1).toFixed(0)}</TableCell>
            <TableCell>{"$" + (order.itemTotal * 1).toFixed(2)}</TableCell>
            {/* <TableCell>
              <DetailsButton onClick={() => handleClick(order.orderId)} />
            </TableCell> */}
          </TableRow>
        ))}
    </React.Fragment>
  );
}

export default ItemHistoryList;
