import React from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import DetailsCard from "../general/DetailsCard";
import PageTitle from "../general/PageTitle";
import PagedTable from "../table/PagedTable";
import Loading from "../general/Loading";
import usePagination from "../../helpers/hooks/usePagination";
import NavigationLink from "../general/links/NavigationLink";
import DashboardLink from "../general/links/DashboardLink";
import CurrentPage from "../general/links/CurrentPage";
import {
  backOrderInformation,
  backOrderItemsColumn,
} from "../../helpers/constants/backOrderDetailsConstants";
import BackOrderDetailsList from "./BackOrderDetailsList";
import useQueryUrl from "../../helpers/hooks/useQueryUrl";

// Renders detail page for individual orders
function BackOrderDetails() {
  const { id } = useParams();
  const { currentPage, pageSize, handlePageChange, handleRowsPerPageChange } =
    usePagination();
  const { data, isLoading } = useQueryUrl("/backorders/" + id);

  if (isLoading) return <Loading />;

  return (
    <React.Fragment>
      {/* Title */}
      <PageTitle title={`Back Order Details`} />
      {/* Link */}
      <div style={{ display: "flex" }}>
        <DashboardLink />
        <NavigationLink name="Back Orders" url="/backorders" />
        <CurrentPage currentPage="Back Order Details" />
      </div>
      {/* Card layout */}
      <Grid container spacing={3}>
        {/* General order info */}
        <Grid item xs={12} md={4}>
          <DetailsCard
            title="Back Order Information"
            data={backOrderInformation(data)}
          />
        </Grid>
        {/* Order items */}
        <Grid item xs={12} md={8}>
          <PagedTable
            title="Back Order Items"
            columns={backOrderItemsColumn}
            length={data.backOrderItems.length}
            currentPage={currentPage}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          >
            <BackOrderDetailsList
              items={data.backOrderItems}
              currentPage={currentPage}
              pageSize={pageSize}
            />
          </PagedTable>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default BackOrderDetails;
