import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

// CSS styling
const useStyles = makeStyles({
  selector: {
    width: 150,
  },
});

// Renders the user filter dialog box
function UserDialog({ open, onModalClose, onChangeFilters }) {
  const classes = useStyles();
  // Filter parameters
  const [isActive, setIsActive] = useState("");
  const [userType, setUserType] = useState("");

  // Changes fetch URL with entered filter
  const handleSubmit = (event) => {
    event.preventDefault();
    onChangeFilters("&is_active=" + isActive + "&user_type=" + userType);
    onModalClose();
  };

  // Resets fetch URL with no filters
  const handleClear = () => {
    setIsActive("");
    setUserType("");
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onModalClose}
        maxWidth="xs"
        fullWidth
        aria-labelledby="order-dialog"
      >
        <form
          autoComplete="off"
          noValidate
          onSubmit={(event) => handleSubmit(event)}
        >
          <DialogTitle id="order-dialog">Add Filters</DialogTitle>
          <DialogContent dividers style={{ overflow: "hidden" }}>
            <Grid
              container
              spacing={3}
              alignItems="flex-start"
              justifyContent="center"
              direction="column"
            >
              {/* Titles */}
              <Grid item container spacing={10}>
                {/* User type title */}
                <Grid item xs={6}>
                  <Typography component="h2" variant="h6">
                    User Type
                  </Typography>
                </Grid>
                {/* Is active title */}
                <Grid item xs={6}>
                  <Typography component="h3" variant="h6">
                    Is Active
                  </Typography>
                </Grid>
              </Grid>
              {/* Fields */}
              <Grid item container spacing={10}>
                {/* User type textfield */}
                <Grid item xs={6}>
                  <TextField
                    className={classes.selector}
                    label="User Type"
                    onChange={(event) => setUserType(event.target.value)}
                    value={userType}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    select
                  >
                    <MenuItem value="" label="All">
                      All
                    </MenuItem>
                    <MenuItem value="1">Admin</MenuItem>
                    <MenuItem value="2">Sales Rep</MenuItem>
                    <MenuItem value="3">Warehouse</MenuItem>
                  </TextField>
                </Grid>
                {/* Is active selector */}
                <Grid item xs={6}>
                  <TextField
                    className={classes.selector}
                    label="Is Active"
                    onChange={(event) => setIsActive(event.target.value)}
                    value={isActive}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    select
                  >
                    <MenuItem value="" label="All">
                      All
                    </MenuItem>
                    <MenuItem value="1">Active</MenuItem>
                    <MenuItem value="0">Inactive</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          {/* Submit and Cancel Buttons */}
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={onModalClose}
            >
              Cancel
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClear}>
              Reset
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Apply
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

export default UserDialog;
