import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import {
  pagePermissions,
  userDictionary,
} from "../../helpers/constants/constants";
import { UserContext } from "../../helpers/UserContext";

function RequirePerms({ children, page }) {
  const { user } = useContext(UserContext);

  if (
    !pagePermissions[page].includes(userDictionary[user.user_type]["type"]) &&
    userDictionary[user.user_type]["type"] !== "admin"
  ) {
    return <Navigate replace to={userDictionary[user.user_type]["redirect"]} />;
  }

  return children;
}

export default RequirePerms;
