import React from "react";
import PagedSortableTable from "../table/PagedSortableTable";
import CustomerList from "./CustomerList";
import { customerColumns } from "../../helpers/constants/constants";
import CustomerDialog from "./CustomerDialog";
import PageTitle from "../general/PageTitle";
import DashboardLink from "../general/links/DashboardLink";
import CurrentPage from "../general/links/CurrentPage";
import { TableCell } from "@mui/material";

// Primary customers component. Renders all customer content
function Customers() {
  return (
    <React.Fragment>
      {/* Title */}
      <PageTitle title={"Your Customers"} />
      {/* Link */}
      <div style={{ display: "flex" }}>
        <DashboardLink />
        <CurrentPage currentPage={"Customers"} />
      </div>
      {/* Table */}
      <PagedSortableTable
        baseUrl={"/customers"}
        columns={customerColumns}
        title="Your Customers"
        BodyComponent={CustomerList}
        DialogComponent={CustomerDialog}
        extra={<TableCell>Details</TableCell>}
      />
    </React.Fragment>
  );
}

export default Customers;
