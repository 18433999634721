import React from "react";
import { backOrderColumns } from "../../helpers/constants/constants";
import PagedSortableTable from "../table/PagedSortableTable";
import BackOrderList from "./BackOrderList";
import BackOrderDialog from "./BackOrderDialog";
import PageTitle from "../general/PageTitle";
import DashboardLink from "../general/links/DashboardLink";
import CurrentPage from "../general/links/CurrentPage";
import { TableCell } from "@mui/material";

// Primary backorders component. Renders all backorder content
function BackOrders() {
  return (
    <React.Fragment>
      {/* Title */}
      <PageTitle title={"Back Orders"} />
      {/* Link */}
      <div style={{ display: "flex" }}>
        <DashboardLink />
        <CurrentPage currentPage={"Backorders"} />
      </div>
      {/* Table */}
      <PagedSortableTable
        baseUrl={"/backorders"}
        columns={backOrderColumns}
        title="Back Orders"
        BodyComponent={BackOrderList}
        DialogComponent={BackOrderDialog}
        extra={<TableCell>Details</TableCell>}
      />
    </React.Fragment>
  );
}

export default BackOrders;
