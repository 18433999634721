import React from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { blue, grey, orange } from "@mui/material/colors";
import Loading from "../general/Loading";
import RadialBarChartPaper from "./RadialBarChartPaper";
import SixMonthBar from "./SixMonthBar";
import RecentOrders from "./RecentOrders";
import RecentInvoices from "./RecentInvoices";
import { formatMoney } from "../../helpers/functions";
import InfoCardsPair from "./InfoCardsPair";
import MonthlyBreakdown from "./MonthlyBreakdown";
import useQueryUrl from "../../helpers/hooks/useQueryUrl";
import Greeting from "./Greeting";
import InfoCard from "./InfoCard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

// Renders all dashboard content and arranges them in a grid
function Dashboard() {
  // Data fetching
  const { data, isLoading } = useQueryUrl("/dashboardinfo");
  const openOrderQuery = useQueryUrl("/openorders");

  // Loading if data is pending
  if (isLoading) {
    return <Loading />;
  }

  // Render component
  return (
    <React.Fragment>
      {/* Title */}
      <Greeting />
      {/* Subtitle */}
      <Typography
        variant="subtitle2"
        sx={{
          color: grey[600],
          marginBottom: 2,
        }}
        gutterBottom
      >
        Here's an overview of your sales analytics
      </Typography>
      {/* Paper layout */}
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6} md={3}>
            {!openOrderQuery.isLoading && (
              <InfoCard
                data={formatMoney(openOrderQuery.data.total)}
                subtitle="Total in Open Orders"
                background={blue["100"]}
              >
                <ShoppingCartIcon sx={{ fontSize: 30, color: blue[500] }} />
              </InfoCard>
            )}
          </Grid>
          <Grid item xs={6} md={3}>
            {!openOrderQuery.isLoading && (
              <InfoCard
                data={formatMoney(
                  openOrderQuery.data.total + data.month0.currentYear
                )}
                subtitle="Sales Potential"
                background={orange["100"]}
              >
                <AttachMoneyIcon sx={{ fontSize: 30, color: orange[500] }} />
              </InfoCard>
            )}
          </Grid>
        </Grid>
        {/* Monthly information infocard and radial bar grouping */}
        <Grid item container xs={12} md={6} spacing={2}>
          {/* Info cards */}
          <InfoCardsPair
            data={data.month0.prevYear}
            difference={data.month0.difference}
            percentDifference={data.month0.percentDifference}
            subtitle="Sales this month last year"
          />
          {/* Radial bar */}
          <Grid item xs={12}>
            <RadialBarChartPaper
              data={data.month0.currentYear}
              endDomain={data.month0.prevYear}
              caption={formatMoney(data.month0.currentYear)}
              subtitle="Total sales this month"
            />
          </Grid>
        </Grid>
        {/* Yearly information infocard radial bar grouping */}
        <Grid item container xs={12} md={6} spacing={2}>
          {/* Info cards */}
          <InfoCardsPair
            data={data.year.prevYear}
            difference={data.year.difference}
            percentDifference={data.year.percentDifference}
            subtitle="Sales total last year"
          />
          {/* Radial bar */}
          <Grid item xs={12}>
            <RadialBarChartPaper
              data={data.year.currentYear}
              endDomain={data.year.prevYear}
              caption={formatMoney(data.year.currentYear)}
              subtitle="Total sales this year"
            />
          </Grid>
        </Grid>
        {/* Past 6 Months Bar Chart */}
        <Grid item xs={12} md={8}>
          <SixMonthBar data={data} />
        </Grid>
        {/* Monthly breakdown */}
        <Grid item xs={12} md={4}>
          <MonthlyBreakdown data={data} />
        </Grid>
        {/* Recent Orders Table */}
        <Grid item xs={12} md={6}>
          <RecentOrders />
        </Grid>
        {/*Recent Invoices Table*/}
        <Grid item xs={12} md={6}>
          <RecentInvoices />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Dashboard;
