import React from "react";
import { makeStyles } from "@mui/styles";
import { Link } from "@mui/material";

// CSS styling
const useStyles = makeStyles({
  link: {
    padding: 10,
  },
});

// Renders the see all link for RecentOrders, RecentInvoices, and TopCustomers components
function SeeAllLink({ text, onClick }) {
  const classes = useStyles();
  return (
    <Link className={classes.link} component="button" onClick={onClick}>
      {text}
    </Link>
  );
}

export default SeeAllLink;
