import { useEffect, useState } from "react";
import axiosInstance from "../axios";
import { useNavigate, useParams } from "react-router-dom";

// Handles user form logic
const useUserForm = (setOpen, userData, method) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [username, setUsername] = useState(userData.username);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(userData.email);
  const [firstName, setFirstName] = useState(userData.first_name);
  const [lastName, setLastName] = useState(userData.last_name);
  const [userType, setUserType] = useState(userData.user_type);
  const [salesRepCode, setSalesRepCode] = useState(userData.sales_rep_code);
  const [warehouse, setWarehouse] = useState(userData.warehouse);
  const [active, setActive] = useState(userData.is_active ? "Yes" : "No");
  const [error, setError] = useState(false);

  useEffect(() => {
    setUsername(userData.username);
    setEmail(userData.email);
    setFirstName(userData.first_name);
    setLastName(userData.last_name);
    setUserType(userData.user_type);
    setSalesRepCode(userData.sales_rep_code);
    setWarehouse(userData.warehouse);
    setActive(userData.is_active ? "Yes" : "No");
  }, [userData]);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Create user request
    if (method === "create") {
      axiosInstance
        .post("/users/", {
          username: username,
          password: password,
          first_name: firstName,
          last_name: lastName,
          user_type: userType,
          email: email,
          sales_rep_code: salesRepCode,
          is_active: active === "Yes" ? true : false,
          warehouse: warehouse,
        })

        .then((response) => {
          setOpen(false);
          setError(false);
        })
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          setError(true);
        });
    }
    // Edit user request
    else {
      let data = {};
      data.username = username;
      if (password) data.password = password;
      data.first_name = firstName;
      data.last_name = lastName;
      data.user_type = userType;
      data.email = email;
      data.sales_rep_code = salesRepCode;
      data.is_active = active === "Yes" ? true : false;
      data.warehouse = warehouse;
      axiosInstance
        .patch("/users/" + id + "/", data)
        .then((response) => {
          console.log(data);
          setError(false);
          navigate("/users");
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
          setError(true);
        });
    }
  };

  return {
    username,
    setUsername,
    password,
    setPassword,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    userType,
    setUserType,
    salesRepCode,
    setSalesRepCode,
    error,
    setError,
    active,
    setActive,
    warehouse,
    setWarehouse,
    handleSubmit,
  };
};

export default useUserForm;
