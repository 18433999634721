import React from "react";
import { Navigate } from "react-router-dom";

function RequireAuth({ children }) {
  if (!localStorage.getItem("access_token")) {
    return <Navigate replace to={"/login"} />;
  }

  return children;
}

export default RequireAuth;
