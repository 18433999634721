import { useState } from "react";
import axios from "../axios";

// Custom hook for pdf generation
const useGetPdf = (fileInfo, fileType) => {
  const [isPending, setIsPending] = useState(false);

  let fileName = "";
  let url = "";
  if (fileType === "creditmemo") {
    fileName = `creditmemo-${fileInfo.customerCode}-${fileInfo.orderNumber}.pdf`;
    url = `/exportcreditmemo/${fileInfo.orderNumber}/`;
  }
  if (fileType === "invoice") {
    fileName = `invoice-${fileInfo.customerCode}-${fileInfo.orderNumber}.pdf`;
    url = `/exportinvoice/${fileInfo.orderId}/`;
  }
  if (fileType === "statement") {
    fileName = `statement-${fileInfo.customerCode}.pdf`;
    url = `/statement/${fileInfo.customerCode}/`;
  }

  const handleClick = () => {
    setIsPending(true);

    axios
      .get(url, {
        Authorization: localStorage.getItem("access_token")
          ? "JWT " + localStorage.getItem("access_token")
          : null,
        responseType: "blob",
        timeout: 120000,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .then(() => {
        setIsPending(false);
      })
      .catch((error) => {
        console.log(error);
        setIsPending(false);
      });
  };

  return { isPending, handleClick };
};

export default useGetPdf;
