import React from "react";
import { Chip, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import DetailsButton from "../general/DetailsButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";

// CSS styles
const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.light,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
  canceled: {
    color: red[600],
  },
}));

// Renders orders data into table rows
function OrderList({ data }) {
  const classes = useStyles();
  const navigate = useNavigate();

  // Details button; redirects to order page
  const handleClick = (orderId) => {
    navigate("/orders/" + orderId);
  };

  // Renders chip for open and close statuses
  const handleStatus = (status) => {
    if (status === "O") {
      return <Chip label="Open" className={classes.warning} />;
    } else if (status === "C") {
      return <Chip label="Closed" className={classes.success} />;
    }
  };

  // Correctly calculates total for canceled orders
  const handleCanceled = (order) => {
    if (order.canceled === "C") {
      return (
        <Typography variant="body2" className={classes.canceled}>
          {"-$" + (order.total * -1).toFixed(2)}
        </Typography>
      );
    }
    return "$" + (order.total * 1).toFixed(2);
  };

  return (
    <React.Fragment>
      {data.map((order) => (
        <TableRow key={order.orderId} hover>
          <TableCell>{order.orderNumber}</TableCell>
          <TableCell>{order.orderDate}</TableCell>
          <TableCell>{order.customerCode}</TableCell>
          <TableCell>{order.shippingAddressName}</TableCell>
          <TableCell>{handleCanceled(order)}</TableCell>
          <TableCell>
            <DetailsButton onClick={() => handleClick(order.orderId)} />
          </TableCell>
          <TableCell>{handleStatus(order.orderStatus)}</TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

export default OrderList;
