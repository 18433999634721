export const customerContact = (data) => {
  return [
    {
      name: "Contact",
      content: data.contact,
    },
    {
      name: "Phone",
      content: data.phone,
    },
    {
      name: "Email",
      content: data.email,
    },
    {
      name: "Fax",
      content: data.fax,
    },
    {
      name: "Bill to Address",
      content: data.billToAddress,
    },
    {
      name: "City",
      content: data.city,
    },
    {
      name: "State",
      content: data.state,
    },
    {
      name: "Zip Code",
      content: data.zipcode,
    },
    {
      name: "Country",
      content: data.country,
    },
  ];
};

export const customerFinances = (data) => {
  return [
    {
      name: "Balance",
      content: "$" + (data.balance * 1).toFixed(2),
    },
    {
      name: "Credit Limit",
      content: "$" + (data.creditLimit * 1).toFixed(2),
    },
  ];
};

export const addressColumns = [
  {
    id: "warehouse",
    label: "Warehouse",
  },
  {
    id: "addressName",
    label: "Address Name",
  },
  {
    id: "shippingAddress",
    label: "Shipping Address",
  },
];
