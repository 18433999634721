import React from "react";
import PagedSortableTable from "../table/PagedSortableTable";
import { itemHistoryColumns } from "../../helpers/constants/constants";
import ItemHistoryList from "./ItemHistoryList";
import ItemHistoryDialog from "./ItemHistoryDialog";
import PageTitle from "../general/PageTitle";
import DashboardLink from "../general/links/DashboardLink";
import CurrentPage from "../general/links/CurrentPage";
import { Box } from "@mui/system";

// Primary invoice component. Renders all invoice content
function ItemHistory() {
  return (
    <React.Fragment>
      {/* Title */}
      <PageTitle title={"Item Sales History"} />
      {/* Link */}
      <Box sx={{ display: "flex" }}>
        <DashboardLink />
        <CurrentPage currentPage={"Item History"} />
      </Box>
      {/* Table */}
      <PagedSortableTable
        baseUrl={"/itemhistory"}
        columns={itemHistoryColumns}
        title="Item Sales History"
        BodyComponent={ItemHistoryList}
        DialogComponent={ItemHistoryDialog}
      />
    </React.Fragment>
  );
}

export default ItemHistory;
