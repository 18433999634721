import { useQuery } from "@tanstack/react-query";
import getUrl from "../getUrl";
import { queryUrl } from "../queryFunctions";

const useQueryPaged = (
  baseUrl,
  currentPage,
  pageSize,
  orderBy,
  order,
  search,
  filters
) => {
  const url = getUrl(
    baseUrl,
    currentPage,
    pageSize,
    orderBy,
    order,
    search,
    filters
  );
  return useQuery(
    [baseUrl, currentPage, pageSize, orderBy, order, search, filters],
    () => queryUrl(url),
    { keepPreviousData: true, cacheTime: 600000 }
  );
};

export default useQueryPaged;
