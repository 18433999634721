// Builds API URL from parameters
function getUrl(
  baseUrl,
  currentPage,
  pageSize,
  orderBy,
  order,
  search,
  filters
) {
  const offset = currentPage * pageSize;
  const ordering = order === "desc" ? "-" : "";

  return `${baseUrl}/?limit=${pageSize}&offset=${offset}&ordering=${ordering}${orderBy}&search=${search}&${filters}`;
}

export default getUrl;
