import { Avatar, Divider, List, ListItem, Typography } from "@mui/material";
import React, { useContext } from "react";
import { UserContext } from "../../../helpers/UserContext";

function DrawerHeader() {
  const { user } = useContext(UserContext);

  return (
    <React.Fragment>
      <List>
        <ListItem>
          <Avatar sx={{ marginRight: 1 }}>
            {user.first_name[0] + user.last_name[0]}
          </Avatar>
          <Typography variant="subtitle2">
            {user.first_name + " " + user.last_name}
          </Typography>
        </ListItem>
      </List>
      <Divider />
    </React.Fragment>
  );
}

export default DrawerHeader;
