import React from "react";
import {
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
} from "@mui/material";
import Typography from "@mui/material/Typography";

// Renders a table that is paginated
function PagedTable({
  title,
  columns,
  length,
  currentPage,
  pageSize,
  handlePageChange,
  handleRowsPerPageChange,
  children,
}) {
  return (
    <Card variant="outlined">
      {/* Table title */}
      <Toolbar>
        <Typography variant="h6">{title}</Typography>
      </Toolbar>
      <Divider />
      {/* Table */}
      <TableContainer>
        <Table>
          {/* Table Head */}
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* Table body */}
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      {/* Table pagination */}
      <TablePagination
        count={length}
        page={currentPage}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[5, 10, { value: -1, label: "All" }]}
        onPageChange={(event, page) => handlePageChange(page)}
        onRowsPerPageChange={(event) =>
          handleRowsPerPageChange(event.target.value)
        }
      />
    </Card>
  );
}

export default PagedTable;
