import React from "react";
import { Delete } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";

// CSS styling
const useStyles = makeStyles({
  icon: {
    padding: 0,
    marginLeft: 4,
  },
});

// Renders the delete row button for tables
function DeleteButton({ onClick }) {
  const classes = useStyles();
  return (
    <IconButton
      className={classes.icon}
      color="primary"
      onClick={onClick}
      size="large"
    >
      <Delete />
    </IconButton>
  );
}

export default DeleteButton;
