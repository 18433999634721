import React from "react";
import { GetApp } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import useGetPdf from "../../helpers/hooks/useGetPdf";
import Loading from "./Loading";

// CSS styling
// const useStyles = makeStyles({
//   icon: {
//     padding: 0,
//   },
// });

// Renders the row details button for tables
function DownloadButton({ fileInfo, fileType }) {
  // const classes = useStyles();
  const { isPending, handleClick } = useGetPdf(fileInfo, fileType);

  return (
    <IconButton
      sx={{ padding: 0 }}
      color="primary"
      onClick={() => handleClick()}
    >
      {isPending ? <Loading /> : <GetApp sx={{ fontSize: 30 }} />}
    </IconButton>
  );
}

export default DownloadButton;
