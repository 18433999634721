import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";

// Renders the customer filter dialog box
function CustomerDialog({ open, onModalClose, onChangeFilters }) {
  // Filter parameters
  const [customer, setCustomer] = useState("");
  const [name, setName] = useState("");
  const [payment, setPayment] = useState("");
  const [group, setGroup] = useState("");

  // Changes fetch URL based on entered filters
  const handleSubmit = (event) => {
    event.preventDefault();
    onChangeFilters(
      "&customer=" +
        customer +
        "&name=" +
        name +
        "&payment=" +
        payment +
        "&group=" +
        group
    );
    onModalClose();
  };

  // Resets fetch URL with no filter
  const handleClear = () => {
    setCustomer("");
    setName("");
    setPayment("");
    setGroup("");
  };

  // Rendered content
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onModalClose}
        maxWidth="xs"
        fullWidth
        aria-labelledby="product-dialog"
      >
        <form
          autoComplete="off"
          noValidate
          onSubmit={(event) => handleSubmit(event)}
        >
          <DialogTitle id="product-dialog">Add Filters</DialogTitle>
          <DialogContent dividers style={{ overflow: "hidden" }}>
            <Grid
              container
              spacing={3}
              alignItems="flex-start"
              justifyContent="center"
              direction="column"
            >
              {/* Textfield Titles */}
              <Grid item container spacing={10}>
                {/* Customer Code Title */}
                <Grid item xs={6}>
                  <Typography component="h2" variant="h6">
                    Customer Code
                  </Typography>
                </Grid>
                {/* Customer Name Title */}
                <Grid item xs={6}>
                  <Typography component="h3" variant="h6">
                    Customer Name
                  </Typography>
                </Grid>
              </Grid>
              {/* Textfields */}
              <Grid item container spacing={10}>
                {/* Customer Code Textfield */}
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Customer Code"
                    value={customer}
                    onChange={(event) => setCustomer(event.target.value)}
                  />
                </Grid>
                {/* Customer Name Textfield */}
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Customer Name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={10}>
                {/* Payment Group Textfield */}
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Payment"
                    value={payment}
                    onChange={(event) => setPayment(event.target.value)}
                  />
                </Grid>
                {/* Group Name Textfield */}
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Group"
                    value={group}
                    onChange={(event) => setGroup(event.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          {/* Submit and Cancel Buttons */}
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={onModalClose}
            >
              Cancel
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClear}>
              Reset
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Apply
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

export default CustomerDialog;
