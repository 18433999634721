import React from "react";
import { PolarAngleAxis, RadialBar, RadialBarChart, Tooltip } from "recharts";
import { makeStyles } from "@mui/styles";

// CSS styling
const useStyles = makeStyles((theme) => ({
  chart: {
    display: "inline",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: -10,
  },
}));

// Renders a radial bar graph based on entered data
function CustomRadialBar({ data, domainEnd }) {
  const classes = useStyles();
  return (
    <RadialBarChart
      className={classes.chart}
      // margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
      height={150}
      width={150}
      cx="50%"
      cy="50%"
      innerRadius="100%"
      barSize={10}
      data={data}
    >
      <PolarAngleAxis
        type="number"
        domain={[0, domainEnd]}
        angleAxisId={0}
        tick={false}
      />
      <Tooltip />
      <RadialBar
        background
        clockWise={false}
        dataKey="value"
        isAnimationActive={true}
      />
    </RadialBarChart>
  );
}

export default CustomRadialBar;
