import { green, red } from "@mui/material/colors";
import { formatMoney, formatPercent } from "../functions";

export const pastSixMonthsData = (data, isPrevPage = false) => {
  let sixMonthData = [];
  let index = isPrevPage
    ? data.year.numCurrentMonth - 1
    : Math.min(5, data.year.numCurrentMonth - 1);

  while (
    isPrevPage
      ? index >= Math.max(data.year.numCurrentMonth - 6, index >= 0)
      : index >= 0
  ) {
    sixMonthData.push({
      name: data[`month${index}`].name,
      sales: data[`month${index}`].currentYear,
      prevSales: data[`month${index}`].prevYear,
      goal: data[`month${index}`].goal,
    });
    index--;
  }

  return sixMonthData;
};

export const monthlyBreakdownData = (data, isPrevPage = false) => {
  let breakdown = [];
  let i = isPrevPage ? Math.max(data.year.numCurrentMonth - 6, 0) : 0;

  while (
    isPrevPage
      ? i < data.year.numCurrentMonth
      : i < Math.min(6, data.year.numCurrentMonth)
  ) {
    breakdown.push({
      name: data[`month${i}`].name + " Difference",
      content:
        formatMoney(data[`month${i}`].difference) +
        ` (${formatPercent(data[`month${i}`].percentDifference)})`,
      color: data[`month${i}`].difference > 0 ? green[700] : red[500],
    });
    i++;
  }

  return breakdown;
};

export const recentOrderColumns = [
  {
    id: "orderNumber",
    label: "Order Number",
  },
  {
    id: "orderDate",
    label: "Order Date",
  },
  {
    id: "customerCode",
    label: "Customer Code",
  },

  {
    id: "shippingAddressName",
    label: "Address Name",
  },

  {
    id: "orderStatus",
    label: "Status",
  },
  {
    id: "total",
    label: "Total",
  },
];
export const recentInvoiceColumns = [
  {
    id: "orderNumber",
    label: "Order Number",
  },
  {
    id: "orderDate",
    label: "Order Date",
  },
  {
    id: "customerCode",
    label: "Customer Code",
  },

  {
    id: "shippingAddressName",
    label: "Address Name",
  },
  {
    id: "trackingNumber",
    label: "Tracking",
  },

  {
    id: "orderStatus",
    label: "Status",
  },
  {
    id: "total",
    label: "Total",
  },
];
