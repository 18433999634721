import { BrowserRouter } from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import React, { useMemo, useState } from "react";
import { emptyUser } from "./helpers/constants/constants";
import { UserContext } from "./helpers/UserContext";
import { indigo, red } from "@mui/material/colors";
import AppRoutes from "./components/general/AppRoutes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// Create the mui theme for the entire app
const theme = createTheme({
  palette: {
    primary: {
      main: indigo[500],
    },
    secondary: {
      main: red[500],
    },
  },
});

// theme = responsiveFontSizes(theme);

// Create a query client for react query
const queryClient = new QueryClient();

// Entry point for the app. Contains entire application
function App() {
  // Stores user information
  const [user, setUser] = useState(emptyUser);
  // Memoizes user for optimization
  const userMemo = useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    // Provide query client for react query
    <QueryClientProvider client={queryClient}>
      {/* Provide styling engine and theme for user interface */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {/* Provide user information for the rest of the app */}
          <UserContext.Provider value={userMemo}>
            {/* Creates routes for the application */}
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </UserContext.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  );
}

export default App;
