import React from "react";
import { TableCell, TableRow, TableSortLabel } from "@mui/material";

// Renders the table head and handles sorting
function HeadRows({ columns, orderBy, order, onTableSort, extra }) {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell key={column.id}>
          <TableSortLabel
            active={orderBy === column.id && column.id !== ""}
            direction={orderBy === column.id ? order : "asc"}
            onClick={() => onTableSort(column.id)}
          >
            {column.label}
          </TableSortLabel>
        </TableCell>
      ))}
      {/* {extra} */}
    </TableRow>
  );
}

export default HeadRows;
